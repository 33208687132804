// Home
.home {
    .testimonials {
        background-color: $green-background;
        position: relative;
        &__container {
            max-width: 1280px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            padding: 75px 220px;
            @include tablet {
                padding: 50px 25px;
            }
        }
        &__title {
            font-family: $kansas;
            font-size: 54px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 50px;
            @include phone {
                font-size: 32px;
                padding: 0 25px;
            }
        }
        &__carousel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 50px;
            .slick-slider {
                width: 100%;
                .slick-arrow {
                    &::before {
                        opacity: 1;
                        color: black;
                        @include tablet {
                            opacity: 0;
                        }
                    }
                }
                .slick-next {
                    &::before {
                        content: url("../../../../assets/bs/arrow-right.png");
                    }
                }
                .slick-prev {
                    &::before {
                        content: url("../../../../assets/bs/arrow-left.png");
                    }
                }
                .testimonial {
                    margin: auto;
                }
            }
        }
        .testimonial {
            display: flex;
            flex-direction: column;
            max-width: 620px;
            &__stars {
                display: flex;
                align-items: center;
                gap: 1rem;
                &--number {
                    background-color: $orange-background;
                    height: 40px;
                    width: 40px;
                    text-align: center;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    p {
                        color: $orange;
                        font-family: $europa;
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
                &--note {
                    display: flex;
                    svg {
                        color: $orange;
                        width: 30px;
                        font-size: 30px;
                    }
                }
            }
            &__text {
                font-family: $europa;
                font-size: 16px;
                line-height: 24px;
                margin-top: 24px;
            }
            &__name {
                font-family: $europa;
                font-size: 16px;
                font-weight: 700;
            }
        }
        &::after {
            content: url("../../../../assets/bs/laitue.png");
            position: absolute;
            top: -75px;
            left: 0;
            @include phone {
                top: -100px;
                left: -30px;
                transform: scale(0.75);
            }
        }
        &::before {
            content: url("../../../../assets/bs/parsley.png");
            position: absolute;
            bottom: -90px;
            right: 0;
            z-index: 20;
            @include tablet {
                visibility: hidden;
            }
        }
    }
}

// Engagement
.commitment {
    .testimonials {
        background-color: $green-background;
        position: relative;
        &__container {
            max-width: 1280px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            padding: 75px 220px;
            @include tablet {
                padding: 50px 25px;
            }
        }
        &__title {
            font-family: $kansas;
            font-size: 54px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 50px;
            @include phone {
                font-size: 32px;
                padding: 0 25px;
            }
        }
        &__carousel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 50px;
            .slick-slider {
                width: 100%;
                .slick-arrow {
                    &::before {
                        opacity: 1;
                        color: black;
                        @include tablet {
                            opacity: 0;
                        }
                    }
                }
                .slick-next {
                    &::before {
                        content: url("../../../../assets/bs/arrow-right.png");
                    }
                }
                .slick-prev {
                    &::before {
                        content: url("../../../../assets/bs/arrow-left.png");
                    }
                }
                .testimonial {
                    margin: auto;
                }
            }
        }
        .testimonial {
            display: flex;
            flex-direction: column;
            max-width: 620px;
            &__stars {
                display: flex;
                align-items: center;
                gap: 1rem;
                &--number {
                    background-color: $orange-background;
                    height: 40px;
                    width: 40px;
                    text-align: center;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    p {
                        color: $orange;
                        font-family: $europa;
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
                &--note {
                    display: flex;
                    svg {
                        color: $orange;
                        width: 30px;
                        font-size: 30px;
                    }
                }
            }
            &__text {
                font-family: $europa;
                font-size: 16px;
                line-height: 24px;
                margin-top: 24px;
            }
            &__name {
                font-family: $europa;
                font-size: 16px;
                font-weight: 700;
            }
        }
        &::after {
            content: url("../../../../assets/bs/parsley.png");
            position: absolute;
            top: -75px;
            left: 0;
            @include tablet {
                visibility: hidden;
            }
        }
        &::before {
            content: url("../../../../assets/bs/feve-complete.png");
            position: absolute;
            bottom: -90px;
            right: 0;
            z-index: 20;
            @include phone {
                visibility: hidden;
            }
        }
    }
}
