.portal__recipe__quickinfo {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: center;
    span {
        margin: 0px 10px;
    }
    background-color: $akt-light-gray;
}
