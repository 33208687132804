.akt {
    // buttons
    button {
        border: none;
    }
    // TOP
    .promo {
        background: $akt-light-blue;
        min-height: 2.5rem;
        // max-height: 40px;
        display: flex;
        align-items: center;
        &__text {
            margin: 0 auto;
            font-size: 16px;
            font-weight: 700;
            color: white;
            padding: 0 1.5rem;
            text-align: center;
            // line-height: 40px;
            @media screen and (max-width: 500px) {
                padding: 0 16px;
            }
            p {
                margin: 5px 0;
            }
        }
    }
    // HERO
    .hero {
        height: calc(100vh - 125px);
        background-image: url("../../../assets/akt/hero-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 1700px) {
            height: calc(100vh - 103.2px);
        }
        @include mixin960 {
            height: calc(75vh - 125px);
        }
        h1 {
            font-size: 140px;
            font-weight: 700;
            color: white;
            text-transform: uppercase;
            filter: drop-shadow(0px 0px 100px #000000);
            line-height: 0.8;
            text-align: center;
            margin-left: 25px;
            margin-right: 25px;
            @include tablet {
                font-size: 80px;
            }
            @include phone {
                font-size: 42px;
            }
            span {
                color: $akt-light-blue;
            }
        }
        button {
            margin-left: 25px;
            margin-right: 25px;
            background-color: #1386ad;
            color: white;
            font-weight: 700;
            border-radius: 8px;
            padding: 13px 40px;
            margin: 0 25px;
            transition: all 0.2s;
            &:hover {
                background-color: white;
                color: $akt-dark-teal;
            }
        }
    }
    // ICONBANNER
    .iconbanner__container {
        width: min(100%, 1770px);
        margin: auto;
        .iconbanner {
            display: grid;
            justify-items: center;
            align-items: center;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            padding: 25px 75px;
            gap: 25px;
            @media screen and (max-width: 1300px) {
                padding: 25px;
            }
            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr 1fr;
                gap: 40px 25px;
            }
            @media screen and (max-width: 500px) {
                padding: 16px;
            }
            .container {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 20px 20px 20px;
                justify-items: center;
                align-items: center;
                gap: 15px;
                @media screen and (max-width: 768px) {
                    gap: 5px;
                }

                img {
                    height: 100%;
                    width: 100%;
                }
                h4 {
                    margin: 0;
                    font-size: 1.4rem;
                    font-weight: 900;
                    color: $akt-light-blue;
                }
                .text {
                    color: #000;
                    font-size: 1rem;
                    text-align: center;
                    line-height: 1.15;
                }
            }
        }
    }
    // SUBHERO
    .subhero {
        max-width: 1770px;
        margin: 100px auto 0;
        padding: 0 75px;
        @include mixin960 {
            padding-top: 50px;
        }
        @media screen and (max-width: 1300px) {
            padding: 0 25px;
        }
        @media screen and (max-width: 768px) {
            padding: 0 25px;
            margin-top: 50px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 16px;
        }
        &__container {
            display: grid;
            grid-template-columns: 55% 45%;
            gap: 30px;
            align-items: center;
            @media screen and (max-width: 1024px) {
                grid-template-columns: 100% 0%;
            }
        }
        &__right {
            display: flex;
            justify-content: center;
            position: relative;
            height: 100%;
            img {
                width: 100%;
                max-height: 100%;
                object-fit: contain;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
        &__left {
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding-bottom: 100px;
            @media screen and (max-width: 768px) {
                gap: 20px;
                padding-bottom: 50px;
            }
        }
        .subhero__title {
            font-weight: 900;
            color: $akt-light-blue;
            font-size: 3.75rem;
            margin: 0;
            line-height: 1.15;
            @include tablet {
                font-size: 3rem;
            }
            @include phone {
                font-size: 2.5rem;
            }
        }
        .subhero__text {
            color: $akt-grey;
            line-height: 1.5;
            margin: 0;
        }
        .subhero__visuals {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 10px;
            .subhero__card {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 25px;
                border-radius: 12px;
                box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
                @media screen and (max-width: 500px) {
                    padding: 16px;
                }
                img {
                    height: 75px;
                    width: 75px;
                    object-fit: contain;
                }
                h4 {
                    text-align: center;
                    font-weight: 900;
                    text-transform: uppercase;
                    line-height: 1rem;
                    text-wrap: balance;
                }
            }
        }
        @include phone {
            a {
                text-align: center;
            }
        }
        .subhero__cta {
            font-weight: 900;
            font-size: 1.125rem;
            line-height: 1.9;
            padding: 13px 40px;
            background-color: black;
            color: white;
            transition: all 0.2s;
            &:hover {
                background-color: $akt-dark-teal;
            }
        }
    }
    // COMPATIBLE
    .compatible {
        background: linear-gradient(#d4d4d4, #fff);
        // background: url(<path-to-image>), lightgray 50% / cover no-repeat;

        &__container {
            height: 100%;
            display: flex;
            gap: 20px;

            // REMOVE THOSE 2 LINES BELOW TO KEEP IMAGES ON THE LEFT AT ALL SIZES
            width: min(100%, 1920px);
            margin: auto;
        }
        .compatible__left {
            @media screen and (max-width: 1550px) {
                width: 50vw;
            }
            @media screen and (max-width: 1023px) {
                display: none;
            }
        }
        .compatible__right {
            width: min(100%, 885px);
            @include tablet {
                width: 100%;
                margin: 0;
            }
            .compatible__content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: end;
                gap: 20px;
                padding: 0 75px;
                @media screen and (max-width: 1700px) {
                    margin-top: 30px;
                    height: calc(100% - 30px);
                }
                @media screen and (max-width: 1550px) {
                    justify-content: center;
                    padding: 0 25px;
                }
                @media screen and (max-width: 1300px) {
                    padding: 0 25px;
                }
                @media screen and (max-width: 1024px) {
                    margin: 50px 0;
                }
                @media screen and (max-width: 768px) {
                    margin-top: 25px;
                }
                @media screen and (max-width: 500px) {
                    padding: 0 16px;
                }
                .compatible__title {
                    font-size: 3rem;
                    @media screen and (max-width: 1700px) {
                        font-size: 2.25rem;
                    }
                }
                span {
                    line-height: 1.5;
                }
                .compatible__content--bottom {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    @media screen and (max-width: 1700px) {
                        grid-template-columns: 1fr;
                    }
                    .compatible__download {
                        display: block;
                        .links {
                            height: 50px;
                            &__container {
                                height: 50px;
                                display: inline-block;
                                padding: 0 8px;
                            }
                        }
                    }
                    .compatible__phones {
                        @media screen and (max-width: 1550px) {
                            display: none;
                        }
                    }
                }
            }
        }
        .compatible__tablet--phones {
            display: none;
            @media screen and (max-width: 1023px) {
                object-fit: contain;
                display: grid;
                align-items: end;
            }
            @media screen and (max-width: 820px) {
                display: none;
            }
        }
    }
    // PROGRAMMES
    .progs__container {
        background-image: url("../../../assets/akt/progs-bg.png");
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
    }
    .progs {
        max-width: 1770px;
        margin: 150px auto 50px;
        padding: 0 125px;
        @media screen and (max-width: 1920px) {
            margin-top: 100px;
        }
        @media screen and (max-width: 1300px) {
            padding-inline: 25px;
        }
        @media screen and (max-width: 768px) {
            margin-top: 50px;
        }
        @media screen and (max-width: 500px) {
            padding-inline: 16px;
        }
        img {
            width: 100%;
            display: block;
            cursor: pointer;
        }
    }
    .progs__title {
        font-weight: 900;
        font-size: 3rem;
        color: $akt-light-blue;
        text-align: center;
        margin: 0;
        line-height: 1.15;
    }
    .progs__text {
        color: $akt-grey;
        max-width: 800px;
        text-align: center;
        margin: 25px auto 50px;
    }
    .progs__content {
        position: relative;

        .progs__white {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            height: 25%;
            width: 99vw;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 24.48%, #fff 44.44%);
        }
        .progs__feat__mobile {
            display: none;
        }
        .progs__teaser__title {
            font-size: 1.5rem;
            font-weight: 600;
            display: inline-block;
        }
        .progs__teaser__description {
            font-size: 1.25rem;
            width: 80%;
        }
        .progs__teaser__duration {
            margin-left: 10px;
            background-color: $akt-light-blue;
            text-transform: uppercase;
            color: white;
            font-weight: bold;
            padding: 8px 16px;
            float: right;
        }
        .progs__unit {
            img {
                border-radius: 8px;
            }
        }
    }
    .progs__cta {
        font-weight: 900;
        font-size: 1.125rem;
        line-height: 1.9;
        padding: 13px 40px;
        background-color: black;
        color: white;
        transition: all 0.2s;
        z-index: 5;
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translate(-50%, 0);
        text-wrap: nowrap;
        &:hover {
            background-color: $akt-dark-teal;
        }
    }
    @media screen and (max-width: 599px) {
        .progs__feat__mobile {
            display: block !important;
        }
        .progs__feat__desktop {
            display: none;
        }
    }
    // PRODUCTS
    .products {
        max-width: 1770px;
        margin: 150px auto 0;
        padding: 0 75px;

        @media screen and (max-width: 1920px) {
            margin-top: 100px;
        }
        @media screen and (max-width: 1300px) {
            padding: 0 25px;
        }
        @media screen and (max-width: 768px) {
            margin-top: 50px;
        }
        @media screen and (max-width: 500px) {
            padding: 0 16px;
        }
    }
    .promo__desktop {
        img {
            max-width: 100%;
        }
    }
    .promo__mobile {
        display: none;
    }
    @media screen and (max-width: 599px) {
        .promo__mobile {
            display: block;
            img {
                max-width: 100%;
            }
        }
        .promo__desktop {
            display: none;
        }
    }
    .products__title {
        text-align: center;
    }
    .products__text {
        text-align: center;
        max-width: 1000px;
        margin: 35px auto 75px;
        line-height: 1.5;
        text-wrap: balance;
    }
    .products__debrouillage {
        background-color: $akt-unscrambling;
        width: 100%;
        padding: 35px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 100px;
        .products__debrouillage--title {
            display: flex;
            align-items: center;
            gap: 10px;
            h3 {
                text-transform: uppercase;
                color: white;
                font-size: 2.5rem;
                font-weight: 900;
                margin: 0;
            }
        }
        span {
            color: white;
            padding: 1rem;
        }
        button {
            background-color: #00000000;
            color: white;
            text-transform: uppercase;
            border-radius: 9.985px;
            border: 1px solid #a4a4a4;
            font-weight: 900;
            font-size: 1.125rem;
            line-height: 1.9;
            padding: 13px 40px;
            transition: all 0.2s;
            &:hover {
                background: white;
                border-color: white;
                color: $akt-dark-teal;
            }
        }
    }
    .products__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        margin-bottom: 50px;
        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
            gap: 50px;
        }
        .products__card {
            border-radius: 12px;
            box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
            position: relative;
            .products__card--sale {
                position: absolute;
                top: 15px;
                right: 15px;
                z-index: 2;
                @media screen and (max-width: 1500px) {
                    top: -40px;
                    right: -15px;
                }
                .text {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 900;
                    span {
                    }
                    .amount {
                        font-size: 29px;
                        line-height: 0.8;
                    }
                }
            }
            &.favorite {
                border: 4px solid $akt-light-blue;
                @media screen and (max-width: 1024px) {
                    grid-row-start: 1;
                }
                .products__card--favorite {
                    position: absolute;
                    text-align: center;
                    width: 65%;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #13ad2c;
                    border-radius: 8px;
                    span {
                        font-size: 1.3rem;
                        font-weight: 700;
                        color: white;
                        text-transform: capitalize;
                    }
                }
                .products__card--title {
                    color: #000;
                }
                .products__card--price {
                    .amount {
                        color: #353535;
                    }
                }
                .products__card--button {
                    background-color: $akt-light-blue;
                    color: white;
                    &:hover {
                        background-color: $akt-dark-teal;
                    }
                }
            }
            .products__card--container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: min(100%, 250px);
                margin: auto;
                padding: 30px 10px;
            }
            .products__card--title {
                color: #606060;
                font-size: 2rem;
                font-weight: 900;
                text-align: center;
                line-height: 1.15;
                text-transform: uppercase;
                text-wrap: balance;
                margin: 0;
                margin-bottom: 75px;
                margin-top: 25px;
            }
            .products__card--price {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #7a7a7a;
                margin-bottom: 50px;
                .amount {
                    font-size: 5rem;
                    font-weight: 900;
                    margin: 0;
                    line-height: 0.75;
                }
            }
            .products__card--once {
                color: $akt-light-blue;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1.5;
                font-size: 0.8rem;
            }
            .products__card--trial {
                color: $akt-light-blue;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1.5;
                font-size: 1rem;
                margin-top: 20px;
                animation: freeTrial 1.5s infinite forwards;
            }
            @keyframes freeTrial {
                50% {
                    opacity: 0.5;
                }
            }
            .products__card--regular {
                text-transform: uppercase;
                display: flex;
                gap: 5px;
                margin-bottom: 30px;
                .old-price {
                    font-weight: 700;
                    position: relative;
                    width: fit-content;
                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .products__card--button {
                background-color: #00000000;
                padding: 13px 40px;
                color: #6a6a6a;
                border-radius: 9.985px;
                border: 1px solid #a4a4a4;
                transition: all 0.2s;
                text-transform: uppercase;
                font-weight: 900;
                &:hover {
                    background-color: $akt-dark-teal;
                    color: white;
                }
            }
        }
    }

    // TESTIMONIALS2
    .testimonials2 {
        // padding: 75px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        gap: 50px;
        @include tablet {
            flex-direction: column;
            padding: unset;
        }
        .slick-slider {
            width: 100%;
            .slick-list {
                .slick-track {
                    .slick-slide {
                        // margin: 0 30px;
                    }
                }
            }
        }
        .slick-slider .slick-dots li button:before {
            font-size: 24px;
        }
        .slick-slider .slick-dots li {
            margin: 0 15px;
        }
        .slick-dots {
            bottom: -40px;
        }
        .item {
            display: flex;
            max-width: 1000px;
            margin: 50px;
            box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 40px 50px;
            @media screen and (max-width: 1300px) {
                margin: 35px 25px;
            }
            @media screen and (max-width: 500px) {
                margin: 35px 16px;
            }
            img {
                width: min(100%, 125px);
            }
            .item__text {
                color: #003f45;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5;
            }
            .item__name {
                color: #027baa;
                font-size: 1.3rem;
                font-weight: 700;
                margin: 0;
            }
        }
    }
    // USE CASE
    .use {
        max-width: 1770px;
        margin: 150px auto 0;
        padding: 0 75px;
        display: grid;
        grid-template-columns: 60% 40%;
        @media screen and (max-width: 1920px) {
            margin-top: 100px;
        }
        @media screen and (max-width: 1300px) {
            padding: 0 25px;
        }
        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
        @media screen and (max-width: 768px) {
            margin-top: 50px;
        }
        @media screen and (max-width: 500px) {
            padding: 0 16px;
        }
        .use__left {
            display: flex;
            flex-direction: column;
            width: min(100%, 1300px);
            .use__text {
                color: #484848;
                font-size: 1.3rem;
            }
            .use__items {
                display: flex;
                gap: 30px;
                margin: 50px 0;
                @media screen and (max-width: 1600px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    justify-items: center;
                    margin-top: 0;
                }
                @media screen and (max-width: 768px) {
                    grid-template-columns: 1fr;
                }
                .use__item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    max-width: 230px;
                    span {
                        text-align: center;
                        font-weight: 900;
                        text-wrap: balance;
                        font-size: 1.1rem;
                        line-height: 1.5;
                        color: #484848;
                    }
                }
            }
        }
        .use__right {
            display: flex;
            justify-content: end;
            align-items: end;
            @media screen and (max-width: 1024px) {
                display: none;
            }
            img {
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
    // CONNECT
    .connect {
        background-color: black;
    }
    .connect__container {
        width: 75%;
        margin: auto;
        padding: 20px;
    }
    .connect__title {
        margin: 0;
        color: white;
        font-weight: 900;
        font-size: 2rem;
        display: inline-block;
    }
    .connect__cta {
        font-weight: 900;
        color: white;
        font-size: 1.5rem;
        padding: 10px 40px;
        background-color: $akt-light-blue;
        border: none;
        float: right;
    }
    @media screen and (max-width: 980px) {
        .connect__cta {
            display: block;
            float: inherit;
        }
    }
    // WHAT
    .what {
        background-color: $akt-light-blue;
        .what__container {
            height: 100%;
            display: flex;
            gap: 20px;

            // REMOVE THOSE 2 LINES BELOW TO KEEP IMAGES ON THE LEFT AT ALL SIZES
            width: min(100%, 1920px);
            margin: auto;
        }
        .what__left {
            @media screen and (max-width: 1550px) {
                width: 50vw;
            }
            @media screen and (max-width: 1023px) {
                display: none;
            }
        }
        .what__right {
            width: min(100%, 885px);
            @media screen and (max-width: 1024px) {
                padding: 50px 0;
            }
            .what__content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 40px;
                padding: 0 75px;
                @media screen and (max-width: 1300px) {
                    padding: 0 25px;
                }
                @media screen and (max-width: 1024px) {
                    padding: 0 25px;
                }
                @media screen and (max-width: 768px) {
                    align-items: center;
                }
                @media screen and (max-width: 500px) {
                    padding: 0 16px;
                }
                .what__title {
                    color: white;
                }
                ul {
                    columns: 2;
                    list-style: none !important;
                    margin-left: 0;
                    padding-left: 0;
                    @media screen and (max-width: 768px) {
                        columns: 1;
                    }
                    li {
                        padding-left: 1em;
                        text-indent: -1em;
                        color: white;
                        line-height: 1.5;
                        overflow: hidden;
                        &:before {
                            content: url("../../../assets/akt/checkmark-black.png");
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
    .what__cta {
        width: fit-content;
        font-weight: 900;
        color: white;
        font-size: 1.125rem;
        padding: 13px 40px;
        background-color: black;
        border: none;
        text-transform: uppercase;
        // margin-top: 40px;
        // margin-bottom: 20px;
        transition: all 0.2s;
        &:hover {
            background-color: $akt-dark-teal;
        }
        @include phone {
            margin: 0;
        }
    }
    // SOCIALS
    .socials {
        background-color: black;
        position: relative;
        margin-top: -5px;
        &::after {
            content: "";
            position: absolute;
            top: -6px;
            width: 100%;
            height: 10px;
            background-color: black;
        }
    }
    .socials__container {
        width: min(calc(100% - 150px), 1770px);
        margin: 0 auto;
        padding: 50px 75px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        @media screen and (max-width: 1300px) {
            width: calc(100% - 50px);
            padding: 35px 25px;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
        @media screen and (max-width: 500px) {
            width: calc(100% - 32px);
            padding: 16px;
        }
    }
    .socials__title {
        color: white;
        font-size: 2.625rem;
        font-weight: 900;
        margin: 0;
        display: inline-block;
        text-wrap: balance;
        @include phone {
            text-align: center;
        }
    }
    .socials__icons {
        float: right;
        margin-left: 20px;
        display: flex;
        @media screen and (max-width: 768px) {
            margin: 0;
        }
        img {
            margin: 0px 10px;
        }
        a {
            display: inline;
        }
    }
    @media screen and (max-width: 1279px) {
        .socials {
            background-size: cover;
        }
    }
    // COACH
    .coach {
        padding: 60px 60px 0;
        position: relative;
        overflow: hidden;
        @include tablet {
            padding: 50px 50px 0;
        }
        @include phone {
            padding: 50px 25px 0;
        }
        .slick-prev:before {
            color: black;
            content: url("../../../assets/akt/back.png");
        }
        .slick-next:before {
            color: black;
            content: url("../../../assets/akt/next.png");
        }
        &__bg {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: 95%;
            @include phone {
                width: 100%;
                transform: translate(-50%, 0) scale(2.5);
            }
        }
    }
    .coach__title {
        font-weight: 900;
        font-size: 3rem;
        color: $akt-light-blue;
        text-align: center;
        margin: 0;
        padding-bottom: 30px;
    }
    .coach__slider {
        position: relative;
        margin-top: 100px;
        .slick-list {
            height: unset;
        }
    }
    .coach__item {
        position: relative;
        img {
            margin: auto;
            max-width: 100%;
            // position: absolute;
        }
        h5 {
            font-weight: 900;
            font-size: 1.5rem;
            color: $akt-light-blue;
            position: absolute;
            right: 70px;
            top: 74px;
            line-height: 1.25;
            @media (max-width: 1500px) {
                right: 15px;
            }
            @include tablet {
                right: 50px;
            }
            @include mixin960 {
                right: 15px;
            }
            @include phone {
                right: 50px;
                font-size: 1rem;
            }
            @media (max-width: 450px) {
                right: 0px;
            }
        }
    }
    .coach__cta {
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 700;
        padding: 13px 40px;
        background-color: black;
        color: white;
        margin: auto;
        display: block;
        transition: all 0.2s;
        position: relative;
        z-index: 1;
        &:hover {
            background-color: $akt-dark-teal;
        }
    }

    // TESTIMONIALS
    .testimonials {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 75px 0;
        @media screen and (max-width: 768px) {
            padding: 50px 0;
        }
        @media screen and (max-width: 600px) {
            padding-inline: 16px;
        }
        .testimonials__title {
            text-align: center;
            margin-bottom: 30px;
        }
        &__subtitle {
            color: $akt-grey;
            margin-bottom: 75px;
            text-align: center;
            @include tablet {
                margin-bottom: 50px;
            }
        }
        &__content {
            display: flex;
            gap: 20px;
            @include tablet {
                flex-direction: column;
                gap: 50px;
            }
        }
        .slick-slider.center {
            width: 100%;
            .slick-arrow {
                display: none !important;
            }
            .slick-track {
                display: flex;
                // gap: 50px;
                padding-bottom: 30px;
            }
        }
        .testimonials__slider {
            width: 100%;
            .slick-arrow {
                display: none !important;
            }
            .slick-track {
                display: flex !important;
                // gap: 100px;
                padding-bottom: 30px;
                @media screen and (max-width: 600px) {
                    gap: unset;
                }
                .slick-slide {
                    height: inherit !important;
                }
            }
        }
        &__item {
            width: min(100%, 600px);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 30px;
            border-radius: 8px;
            &--content {
                position: relative;
                border-radius: 8px;
                background: #fff;
                box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
                padding: 100px 35px 40px;
                margin-top: 125px;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-inline: 50px;

                @include tablet {
                    max-width: 500px;
                }
                @media screen and (max-width: 500px) {
                    margin-inline: 16px;
                }
                img {
                    position: absolute;
                    top: -125px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 200px;
                    height: 200px;
                    border-radius: 8px;
                }
                .testimonials__name {
                    font-family: $Sue;
                    font-size: 4rem;
                    color: $akt-light-blue;
                    margin: 0;
                    text-align: center;
                    line-height: 1.15;
                }
                .testimonials__text {
                    color: #0e0e0e;
                    line-height: 1.5;
                }
                .testimonials__change {
                    position: absolute;
                    width: 75%;
                    bottom: -17px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    background: $akt-light-blue;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    span {
                        color: white;
                        font-size: 1.1rem;
                        font-weight: 700;
                        text-align: center;
                    }
                }
            }
        }
    }
    // FACEBOOK
    .facebook {
        background: $akt-light-blue;

        &__container {
            height: 100%;
            display: flex;
            grid-gap: 20px;
            gap: 20px;

            // REMOVE THOSE 2 LINES BELOW TO KEEP IMAGES ON THE LEFT AT ALL SIZES
            width: min(100%, 1920px);
            margin: auto;
            @media screen and (max-width: 1023px) {
                grid-template-columns: 1fr;
            }
        }
        .facebook__left {
            // width: 50vw;
            @media screen and (max-width: 1550px) {
                width: 50vw;
            }
            @media screen and (max-width: 1023px) {
                display: none;
            }
        }
        .facebook__right {
            width: min(calc(100% - 50px), 885px);
            display: flex;
            justify-content: center;
            @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 75px 0;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                margin: 50px 0;
            }
            .facebook__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;
                padding: 0 75px;
                @media screen and (max-width: 1300px) {
                    padding: 0 25px;
                }
                @media screen and (max-width: 1023px) {
                    align-items: center;
                    gap: 30px;
                }
                @media screen and (max-width: 500px) {
                    padding: 0 16px;
                }
                .facebook__title {
                    font-size: 3rem;
                    color: white;
                    @media screen and (max-width: 1700px) {
                        font-size: 2.25rem;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 2.25rem;
                        text-align: center;
                    }
                }
                span {
                    line-height: 1.5;
                    color: white;
                    font-size: 1.4rem;
                    @media screen and (max-width: 1023px) {
                        text-align: center;
                    }
                }
                ul {
                    columns: 2;
                    list-style: none !important;
                    margin-left: 0;
                    padding-left: 0;
                    @media screen and (max-width: 768px) {
                        columns: 1;
                    }
                    li {
                        padding-left: 1em;
                        text-indent: -1em;
                        color: white;
                        line-height: 1.5;
                        overflow: hidden;
                        &:before {
                            content: url("../../../assets/akt/checkmark-black.png");
                            padding-right: 10px;
                        }
                    }
                }
                .facebook__cta {
                    width: fit-content;
                    font-weight: 900;
                    color: white;
                    font-size: 1.125rem;
                    padding: 13px 40px;
                    background-color: black;
                    border: none;
                    text-transform: uppercase;
                    // margin-top: 40px;
                    // margin-bottom: 20px;
                    transition: all 0.2s;
                    &:hover {
                        background-color: $akt-dark-teal;
                    }
                    @include phone {
                        margin: 0;
                    }
                }
            }
        }
    }
    // FAQ
    .faq {
        max-width: 1770px;
        margin: 75px auto;
        padding: 0 75px;
        @media screen and (max-width: 1300px) {
            padding: 0 25px;
        }
        @media screen and (max-width: 1024px) {
            margin: 50px auto;
        }
        @media screen and (max-width: 500px) {
            padding: 0 16px;
        }
    }
    .faq__title {
        margin-bottom: 50px;
    }
    .faq__container {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .MuiPaper-elevation1 {
            box-shadow: none;
        }
        .MuiAccordionDetails-root {
            p {
                color: #484848;
                font-weight: 400;
            }
        }
        .MuiSvgIcon-root {
            color: $akt-light-blue;
            width: 3 0px;
            height: 30px;
        }
        .MuiAccordionSummary-root.Mui-expanded {
            min-height: initial;
        }
        .MuiAccordionSummary-content.Mui-expanded {
            margin: initial;
        }
        p {
            color: black;
            font-weight: 600;
            font-size: 1.25rem;
        }
        .faq__firstHalf {
            & > * {
                &:last-child {
                    @media screen and (max-width: 960px) {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .faq__item {
            padding: 1rem;
            margin: 1rem;
            border-radius: 8px !important;
            background: #fff;
            box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
            @include mixin960 {
                padding: 1rem 0;
                margin: 1rem 0;
            }
            .MuiAccordionSummary-root {
            }
            &::before {
                content: unset;
            }
        }
    }
    .faq__bg {
        max-width: 100%;
    }
    .faq__cta {
        font-weight: 900;
        color: white;
        font-size: 1.125rem;
        padding: 13px 40px;
        background-color: black;
        border: none;
        text-transform: uppercase;
        margin-top: 40px;
        margin-bottom: 20px;
        width: fit-content;
        transition: all 0.2s;
        &:hover {
            background-color: $akt-dark-teal;
        }
    }
    // FACT
    .fact {
        background: linear-gradient(white 15%, #d4d4d4 15%, #f1f1f1, #f5f5f5);
        @media screen and (max-width: 768px) {
            background: linear-gradient(#d4d4d4, #f1f1f1, #f5f5f5);
        }
    }
    .fact__container {
        max-width: 1770px;
        margin: auto;
        background: url("../../../assets/akt/fact-bg.png") no-repeat center center;
        background-size: cover;
        padding: 395px 200px 200px 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        @include tablet {
            padding: 150px 100px 100px;
        }
        @include phone {
            background: unset;
            padding: 50px 25px;
        }
    }
    .fact__content {
        margin-left: 30rem;
        @include phone {
            margin-left: 0;
        }
    }
    .fact__title {
        font-weight: 800;
        font-size: 4rem;
        margin-top: 1rem;
        color: $akt-light-blue;
        line-height: 1.15;
    }
    .fact__subtitle {
        font-weight: 500;
        font-size: 1.5rem;
        color: black;
        text-wrap: balance;
        line-height: 1.5;
    }
    .fact__cta {
        border: none;
        padding: 13px 40px;
        // border-radius: 25px;
        color: white;
        font-weight: 900;
        font-size: 1.125rem;
        background-color: black;
        transition: all 0.2s;
        &:hover {
            background-color: $akt-dark-teal;
        }
    }
    // FOOTER
    .footer {
        background-color: #0e0e0e;
    }
    .footer__container {
        max-width: 1770px;
        margin: 0 auto;
        padding: 100px 0;
        display: flex;
        justify-content: space-around;
        gap: 50px;
        @media screen and (max-width: 1024px) {
            margin: 0 25px;
            display: grid;
            gap: 25px;
            grid-template-columns: 2fr 1fr;
        }
        @media screen and (max-width: 768px) {
            padding: 50px 0;
            margin: 0 25px;
            display: grid;
            gap: 25px;
            grid-template-columns: 1fr;
        }
    }
    .footer__logo {
        img {
            max-width: 150px;
        }
        @media screen and (max-width: 1024px) {
            grid-row-start: 2;
        }
        @media screen and (max-width: 768px) {
            grid-row-start: 3;
        }
    }
    .footer__mission {
        .footer__mission--title {
            color: $akt-light-blue;
            font-size: 1.75rem;
            font-weight: 700;
        }
        ul {
            list-style: none !important;
            margin-left: 0;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            li {
                font-size: 1.5rem;
                padding-left: 1em;
                text-indent: -1em;
                color: white;
                line-height: 1.75;
                overflow: hidden;
                &:before {
                    content: url("../../../assets/akt/checkmark-white.png");
                    padding-right: 10px;
                }
            }
        }
    }
    .footer__links {
        color: white;
        display: flex;
        flex-direction: column;
        font-size: 1.5rem;
        @media screen and (max-width: 1024px) {
        }
        h6 {
            font-size: 1.5rem;
            font-weight: 700;
            margin: 0;
        }
    }
    // BRANDS
    .brands {
        background-color: #ececec;
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 50px;
            gap: 25px;
            @include phone {
                padding: 50px 25px;
            }
            @media screen and (max-width: 500px) {
                padding: 50px 16px;
            }
            .title {
                color: $akt-light-blue;
                font-size: 30px;
                font-weight: 700;
                text-align: center;
                margin: 0;
                line-height: 1.5;
                @include tablet {
                    margin-bottom: 25px;
                }
            }
        }
        &__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1770px;
            gap: 20px;
            width: 100%;
            @media screen and (max-width: 1550px) {
                // grid-template-columns: 1fr 1fr 1fr;
                // gap: 25px;
                // justify-items: center;
            }
            @media screen and (max-width: 500px) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                justify-items: center;
            }
            img {
                max-width: 100%;
                @media screen and (max-width: 1500px) {
                    max-height: 50px;
                }
            }
            .mcf {
                @media screen and (max-width: 1550px) {
                    grid-column-start: 2;
                    grid-row-start: 3;
                }
                @media screen and (max-width: 500px) {
                    grid-column-start: 1;
                    grid-column-end: 4;
                }
            }
        }
    }
    // LH
    .lh {
        background-color: black;
        .lh__container {
            max-width: 1770px;
            padding: 0 75px;
            margin: auto;
            min-height: 2.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 1300px) {
                padding: 0 25px;
            }
            @media screen and (max-width: 500px) {
                padding: 0 16px;
            }
            .propulsed {
                display: flex;
                text-align: start;
            }
            .politic {
                display: flex;
                text-align: end;
                gap: 10px;
                :hover {
                    cursor: pointer;
                }
            }
            span {
                line-height: 1.5;
                margin: 0 auto;
                font-size: 16px;
                color: white;
                text-align: center;
                padding: 5px 0;
                @media screen and (max-width: 500px) {
                    text-align: unset;
                }

                a {
                    line-height: 1.5;
                    color: #027bba;
                    font-weight: 700;
                }
            }
        }
    }
}
