.plan {
    max-width: 1280px;
    margin: auto;
    padding: 50px 120px 75px;
    &__container {
    }
    &__unique {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 120px;
        border: 1px solid rgba(74, 74, 74, 0.08);
        box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        padding: 0 50px;
        .image {
            position: absolute;
            left: 0;
            bottom: 0;
            max-width: 250px;
            width: 100%;
        }
        &--text {
            display: flex;
            flex-direction: column;
            margin-left: 200px;
            gap: 4px;
            .title {
                font-family: $kansas;
                font-size: 20px;
                font-weight: 600;
                margin: 0;
            }
            .desc {
                font-family: $europa;
                font-size: 16px;
                color: $grey;
                margin: 0;
            }
        }
        &--right {
            display: flex;
            gap: 75px;
            align-items: center;
            .container {
                display: flex;
                gap: 8px;
                align-items: center;
            }
            .price {
                font-family: $europa;
                font-size: 34px;
                font-weight: 700;
                color: $green;
            }
            .per {
                font-family: $europa;
                font-size: 16px;
                color: $grey;
            }
        }
        &--cta {
            float: right;
        }
    }
    &__content {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        width: 100%;
        margin-top: 85px;
        .card {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 25px;
            border-radius: 25px;
            padding: 150px 25px 25px;
            width: 100%;
            box-shadow: 0px 22px 16px rgba(0, 0, 0, 0.08);
            &__image {
                position: absolute;
                top: -30px;
                right: 30px;
            }
            &__tag {
                display: flex;
                align-items: center;
                gap: 8px;
                border-radius: 46px;
                padding: 6px 12px;
                img {
                    width: 12px;
                    object-fit: contain;
                }
                p {
                    font-family: $europa;
                    font-size: 13px;
                    font-weight: 700;
                    margin: 0;
                }
              }
              .eco {
                  color: $yellow;
                  border: 1px solid rgba(250, 171, 57, 0.2);
              }
              .pop {
                  color: $red;
                  border: 1px solid rgba(219, 57, 57, 0.2);
              }
              &__text {
                text-align: center;
                .title {
                  font-family: $kansas;
                  font-size: 20px;
                  font-weight: 600;
                  margin: 0;
                }
                .desc {
                  font-family: $europa;
                  font-size: 16px;
                  color: $grey;
                  margin: 0;
                }
              }
              &__price {
                padding: 15px 0;
                .container {
                  display: flex;
                  align-items: center;
                  gap: 6px;
                  .price {
                    font-family: $europa;
                    font-size: 34px;
                    font-weight: 700;
                    color: $green;
                    margin: 0;
                  }
                  .price-old {
                    font-family: $europa;
                    font-size: 16px;
                    font-weight: 700;
                    opacity: 0.3;
                    text-decoration: line-through;
                  }
                }
                .per {
                  font-family: $europa;
                  font-size: 16px;
                  color: $grey;
                  margin: 0;
                  text-align: center;
                  opacity: 0.8;
                }
              }

        }
    }
}
