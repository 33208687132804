.footprint {
  background-color: $orange-background;
  &__container {
    max-width: 1280px;
    margin: auto;
    padding: 75px 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    @include tablet {
      padding: 50px 25px;
    }
    @include phone {
      flex-direction: column;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 500px;
    &--section {
      color: $orange;
      font-family: $europa;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
      letter-spacing: 1px;
    }
    &--title {
      font-family: $kansas;
      font-size: 38px;
      font-weight: 600;
      margin: 0;
      @include phone {
        font-size: 26px;
      }
    }
    &--text {
      font-family: $europa;
      font-size: 16px;
      color: $grey90;
      margin: 0;
    }
  }
  &__right {
    @include tablet {
      min-width: 40%;

    }
    &--image {
      width: 100%;
    }
  }
}