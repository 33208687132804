.bill__container {
    width: 790px;
    height: 1100px;
    background-color: white;
    margin: 0 auto;
    padding: 20px;

    img {
        max-height: 500px;
        max-width: 400px;
    }

    h5 {
        font-weight: bold;
        color: $akt-light-blue;
    }

    .refund__table {
        color: red;

        th,
        td {
            color: red;
        }
    }
}
