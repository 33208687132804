.productOrder {
    border-radius: 25px;
    display: flex;
    justify-content: center;
    margin: 10px;
    position: relative;
    &__bottom {
        margin-top: 20px;
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        &--number {
            font-family: $europa;
            font-size: 28px;
            margin: 0;
        }
        &--less {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(74, 74, 74, 0.08);
            border-radius: 80px;
            font-family: $europa;
            font-size: 24px;
            font-weight: 700;
            margin: 0;
        }
        &--more {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $europa;
            font-size: 24px;
            font-weight: 700;
            margin: 0;
            background: linear-gradient(90deg, #e7762d 0%, #f99f24 100%);
            border-radius: 64px;
            color: white;
        }
        &--container {
            position: absolute;
            bottom: 0;
            height: 40px;
            width: 50%;
            background-color: white;
            border-top-right-radius: 27px;
            border-top-left-radius: 27px;
            border-top: 1px solid rgba(35, 35, 35, 0.14);
            border-right: 1px solid rgba(35, 35, 35, 0.14);
            border-left: 1px solid rgba(35, 35, 35, 0.14);
            &::before {
                content: "";
                position: absolute;
                bottom: 0px;
                left: 0px;
                margin-left: -29px;
                height: 23px;
                width: 30px;
                border-bottom-right-radius: 100%;
                background-color: #f5f5f5;
                border-bottom: 1px solid rgba(35, 35, 35, 0.14);
                box-shadow: 8px 5px 0 5px white;
            }
            &::after {
                content: "";
                position: absolute;
                bottom: 0px;
                right: 0px;
                margin-right: -29px;
                height: 23px;
                width: 30px;
                border-bottom-left-radius: 100%;
                background-color: #f5f5f5;
                border-bottom: 1px solid rgba(35, 35, 35, 0.14);
                box-shadow: -6px 6px 0 5px white;
            }
        }
    }
    &__container {
        border-radius: 25px;
        width: 100%;
        height: 315px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        padding: 20px 25px;
        background-color: rgba(0, 0, 0, 0.04);
        border: 1px solid rgba(35, 35, 35, 0.14);
        overflow: hidden;
    }
    &__title {
        font-family: $europa;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }
    &__desc {
        font-family: $europa;
        font-size: 15px;
        color: white;
        font-weight: 400;
        opacity: 0.8;
    }
    &.create {
        .productOrder {
            &__eye {
                position: absolute;
                left: 25px;
                top: 25px;
                padding: 10px;
                background-color: #00000050;
                border-radius: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &__price {
                position: absolute;
                right: 25px;
                top: 25px;
                padding: 10px;
                background-color: #00000050;
                border-radius: 40px;
                .price {
                    font-family: $europa;
                    font-size: 13px;
                    font-weight: 700px;
                    color: white;
                    margin: 0;
                }
            }
            &__title {
                color: white;
                z-index: 3;
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
            &__background {
                position: absolute;
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 25px;
            }
            &__bottom {
                &--container {
                    &::after {
                        background-color: unset;
                    }
                    &::before {
                        background-color: unset;
                    }
                }
            }
        }

        
    }
}
