// Defaults
body {
    overflow-y: scroll !important;
}

.centered__parent {
    display: flex;
    justify-content: center;
}

body,
html,
section {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}
a,
a:hover,
a:active {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}
:focus {
    outline: none;
}
.visible {
    visibility: visible;
}
.hidden {
    visibility: hidden;
}
button {
    font-family: inherit;
    cursor: pointer !important;
    &:disabled {
        cursor: not-allowed !important;
        background-color: #eee !important;
        color: white !important;
        border: 4px solid #eee !important;
    }
}
// Custom Scrollbar
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: lightgrey;
}
// Font scaling
/* default */
html {
    font-size: 16px;
}
@media screen and (min-width: 1920px) {
    /* xl */
    html {
        font-size: 16px;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
    /* lg */
    html {
        font-size: 14px;
    }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
    /* md */
    html {
        font-size: 12px;
    }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
    /* sm */
    html {
        font-size: 12px;
    }
}
@media screen and (min-width: 0) and (max-width: 599px) {
    /* xs */
    html {
        font-size: 12px;
    }
}

// Responsive iFrame
.iframe__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.iframe__iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.element__centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clickable__tag {
    cursor: pointer;
}

.submit__fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

// MUI defaults
.MuiPaper-rounded {
    border-radius: 20px !important;
}

// Remove top spacing for first container element
.no-margin-top {
    margin-top: 0px !important;
}

.mui-font {
    color: $mui-grey;
    font-size: 1.5rem;
}

// mainly used for sidebard cards
.boutique__card {
    background-color: white;
    min-width: 300px;
    border-radius: 15px;
    padding: 20px;
    font-family: "Inter", sans-serif;

    .header {
        font-weight: bold;
        font-size: 1.5rem;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
    }

    .body {
        padding-top: 20px;
        color: $akt-dark-teal;
        font-weight: bold;
        text-align: center;
    }
}
// GDPR
.gdpr_container {
    font-family: $Roboto !important;
}
.gdpr_decline {
    background-color: transparent !important;
    border-radius: 5px !important;
}
.gdpr_accept {
    background-color: #007faa !important;
    font-weight: bold !important;
    color: white !important;
    border-radius: 5px !important;
}
