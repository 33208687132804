// Home
.home {
	.header {
		background: url("../../../../assets/bs/header-bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 550px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: white;
		&__title {
			font-family: $kansas;
			font-weight: 600;
			margin-bottom: 3rem;
			font-size: 58px;
			text-align: center;
			@include phone {
				font-size: 32px;
			}
		}
		&__subtitle {
			&--concept {
				font-size: 24px;
				text-align: center;
				font-family: $europa;
				display: flex;
				gap: 1rem;
				align-items: center;
				margin-bottom: 3rem;
				h4 {
					margin: 0;
				}
				@include phone {
					font-size: 20px;
					flex-direction: column;
				}
			}
			&--jimmy {
				font-family: $kansas;
				font-weight: 500;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 1rem;
			}
		}
		@include phone {
			background: url("../../../../assets/bs/header-phone.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}
}

// Menus
.menus {
	.header {
		position: relative;
		&__container {
			max-width: 1280px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 50px;
			padding: 75px 120px;
			@include tablet {
				padding: 50px 25px;
			}
		}
		&__title {
			font-family: $kansas;
			font-size: 58px;
			font-weight: 600;
			margin: 0;
			text-align: center;
			@include phone {
				font-size: 32px;
			}
		}
		&__carousel {
			width: 100%;
			// &--dots {
			// 	// display: flex !important;
			// 	li {
			// 		// color: #4A4A4A20;
			// 		width: 72px;
			// 		&::marker {
			// 			width: 72px;
			// 			background-color: black;

			// 		}
			// 	}
			// 	button {
			// 		visibility: hidden;
			// 	}
			// }
		}
		&::before {
			content: url("../../../../assets/bs/feve.png");
			position: absolute;
			top: 125px;
			left: 0;
			@include phone {
				transform: scale(0.5);
				left: -35px;
				top: 75px;
			}
		}
		&::after {
			content: url("../../../../assets/bs/ail.png");
			position: absolute;
			top: 75px;
			right: 0;
			@include phone {
				transform: scale(0.5);
				right: -34px;
			}
		}
	}
	.slide {
		padding: 0 2rem;
		&__image {
			position: relative;
			width: 100%;
			object-fit: cover;
		}
		&__title {
			font-family: $europa;
			font-size: 30px;
			font-weight: 700;
			color: white;
			position: absolute;
			bottom: 30px;
			padding-left: 50px;
			margin: 0 0 35px 0;
			@include tablet {
				bottom: 10px;
			}
			@include phone {
				font-size: 22px;
				padding-left: 20px;
			}
		}
		&__subtitle {
			font-family: $europa;
			font-size: 15px;
			color: #ffffff80;
			position: absolute;
			bottom: 30px;
			padding-left: 50px;
			// margin: 0;
			@include tablet {
				bottom: 10px;
			}
			@include phone {
				padding-left: 20px;
			}
		}
	}
	 /* Dots */
	 .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
		display: flex;
		padding-left: 2rem;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    // transition: width 0.3s ease-in-out;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 10px;
    height: 10px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 10px;
    height: 10px;

    content: "•";
    text-align: center;

    opacity: 0.25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
  }

	/* Custom Slick Dots */

  @keyframes loading {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .ft-slick__dots--custom {
    height: 4px;
    width: 56px;
    background-color: #e5e7e9;
    border-radius: 4px;
    position: relative;
  }

  .slick-dots li {
    width: 56px;
    margin: 0 2px;
    // transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active {
    width: 56px;
    // transition: width 0.3s ease-in-out;
		.ft-slick__dots--custom {
			background-color: black;
		}
  }

  .slick-dots .slick-active .ft-slick__dots--custom {
    width: 56px;
    top: 0px;
    overflow: hidden;

    .loading {
      height: 4px;
      // animation: loading 5s ease-in;
      background-image: linear-gradient(270deg, #4fd15a, #03ac0e);
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
    }
  }
}

// Fonctionnement
.how {
	.header {
		position: relative;
		padding: 100px 120px;
		@include tablet {
			padding: 50px 25px;
		}
		&__container {
			max-width: 1280px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 40px;
		}
		&__section {
			font-family: $europa;
			font-size: 12px;
			font-weight: 700;
			color: $orange;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
		&__title {
			font-family: $kansas;
			font-size: 58px;
			font-weight: 600;
			margin: 0;
			text-align: center;
			@include phone {
				font-size: 32px;
			}
		}
		&::before {
			content: url("../../../../assets/bs/feve.png");
			position: absolute;
			top: 125px;
			left: 0;
			@include tablet {
				top: 50px;
			}
			@include phone {
				visibility: hidden;
			}
		}
		&::after {
			content: url("../../../../assets/bs/ail.png");
			position: absolute;
			top: 75px;
			right: 0;
			@include tablet {
				top: unset;
				bottom: -200px;
			}
			@include phone {
				visibility: hidden;
			}
		}
	}
}
// Engagement
.commitment {
	.header {
		position: relative;
		padding: 100px 120px;
		@include tablet {
			padding: 50px 25px;
		}
		&__container {
			max-width: 1280px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 40px;
		}
		&__section {
			font-family: $europa;
			font-size: 12px;
			font-weight: 700;
			color: $orange;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
		&__title {
			font-family: $kansas;
			font-size: 58px;
			font-weight: 600;
			margin: 0;
			text-align: center;
			max-width: 500px;
			@include phone {
				font-size: 32px;
			}
		}
		&::before {
			content: url("../../../../assets/bs/basilic.png");
			position: absolute;
			bottom: -75px;
			left: 0;
			@include tablet {
				top: 50px;
			}
			@include phone {
				visibility: hidden;
			}
		}
		&::after {
			content: url("../../../../assets/bs/poire.png");
			position: absolute;
			top: 50px;
			right: 0;
			@include tablet {
				top: unset;
				bottom: -200px;
			}
			@include phone {
				visibility: hidden;
			}
		}
	}
}

// Propos
.about {
	.header {
		max-width: 1280px;
		margin: auto;
		padding: 75px 120px;
		@include tablet {
			padding: 50px 25px;
		}
		&__container {
			display: flex;
			justify-content: space-evenly;
			gap: 50px;
		}
		&__content {
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 25px;
			&--section {
				margin: 0;
				font-family: $europa;
				font-size: 12px;
				font-weight: 700;
				color: $orange;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
			&--title {
				margin: 0;
				font-family: $kansas;
				font-size: 58px;
				font-weight: 600;
				@include phone {
					font-size: 32px;
				}
			}
		}
		&__image {
			display: flex;
			align-items: center;
			object-fit: cover;
			width: 40%;
			max-width: 350px;
			img {
				width: 100%;
			}
		}
	}
}

// Faq
.faq {
	.header {
		position: relative;
		padding: 100px 120px;
		@include tablet {
			padding: 50px 25px;
		}
		&__container {
			max-width: 1280px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 40px;
		}
		&__section {
			font-family: $europa;
			font-size: 12px;
			font-weight: 700;
			color: $orange;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
		&__title {
			font-family: $kansas;
			font-size: 58px;
			font-weight: 600;
			margin: 0;
			text-align: center;
			@include phone {
				font-size: 32px;
			}
		}
		&::before {
			content: url("../../../../assets/bs/laitue.png");
			position: absolute;
			top: 100px;
			left: 0;
			@include tablet {
				top: 0px;
			}
			@include phone {
				transform: scale(0.5);
				left: -60px;
			}
		}
		&::after {
			content: url("../../../../assets/bs/poivron.png");
			position: absolute;
			top: 100px;
			right: 0;
			@include tablet {
				visibility: hidden;
			}
		}
	}
}
