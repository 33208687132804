@import "../../../../scss/parts/shared/_variables.scss";

.container {
    .header {
        .title {
        }

        .count {
            $length: 30px;
            color: white;
            background-color: $akt-dark-teal;
            border-radius: 50px;
            width: $length;
            height: $length;
            text-align: center;
        }
    }

    .cart__items {
        .no__items {
            // add style if needed
        }

        .see__more {
            display: flex;
            flex-direction: column;
        }

        .item {
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                width: 40px;
                border: 1px solid #eee;
                border-radius: 15px;
            }

            .info {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .name {
                    font-size: 12px;
                }
            }

            .price {
                color: #0e80a8;
            }
        }
    }
}
