.upsell__image {
    max-height: 125px;
    max-width: 100%;
}

.image__cart {
    max-width: 50px;
    max-height: 50px;
}
.upsell__container__2 {
    border-right: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
}
.upsell__border {
    border: solid 2px $shared-light-gray;
    margin-top: 10px;
    animation: glow 2.5s infinite;
}
@keyframes glow {
    50% {
        border-color: #3f51b5;
    }
}

// Vente Flash
.flash__dialog {
    .MuiDialog-paperWidthSm {
        max-width: 750px;
    }
}
.flash__title {
    text-transform: uppercase;
    .MuiTypography-h6 {
        font-weight: bold !important;
    }
    &.faim {
        color: $mcf-blue;
    }
    &.Matcha {
        color: $gm-green;
    }
    &.Aktivation {
        color: $akt-light-blue;
    }
    &.Choko {
        color: $gc-brown;
    }
    &.Krisp {
      color: $gk-dark-beige;
    }
}
.flash__image__container {
    display: flex;
}
.flash__image {
    max-width: 100%;
    object-fit: contain;
}
.flash__header {
    font-weight: 900;
    font-size: 3rem;
    display: block;
}
.flash__subheader {
    color: white;
    font-weight: 700;
    padding: 5px 10px;
    font-size: 1.25rem;
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
      background-color: $gk-dark-beige;
  }
}
.flash__description {
    font-size: 1.25rem;
}
.flash__promo {
    font-size: 1.25rem;
    p {
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
}
.flash__seperator {
    height: 80%;
    margin: auto;
    width: 2px;
    background-color: black;
}
.flash__pricetext {
    font-size: 1.175rem;
    p {
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
}
.flash__discount {
    font-weight: 900;
    font-size: 2.5rem;
    display: block;
}
.flash__price {
    font-weight: 700;
    font-size: 1.25rem;
    display: block;
    color: red;
}
.flash__cta {
    display: block !important;
    margin-top: 10px !important;
    .MuiButton-label {
        color: white;
        font-weight: 600;
        font-size: 1.275rem;
    }
    &.faim {
        background-color: $mcf-blue;
        &:hover {
            background-color: $mcf-blue;
            opacity: 0.8;
        }
    }
    &.Matcha {
        background-color: $gm-green;
        &:hover {
            background-color: $gm-green;
            opacity: 0.8;
        }
    }
    &.Aktivation {
        background-color: $akt-light-blue;
        &:hover {
            background-color: $akt-light-blue;
            opacity: 0.8;
        }
    }
    &.Choko {
        background-color: $gc-brown;
        &:hover {
            background-color: $gc-brown;
            opacity: 0.8;
        }
    }
    &.Krisp {
      background-color: $gk-dark-beige;
      &:hover {
          background-color: $gk-dark-beige;
          opacity: 0.8;
      }
  }
}
.flash__refuse {
    margin-top: 10px !important;
    font-size: 1rem;
    .MuiButton-label {
        text-decoration: underline;
    }
}
.flash__taxes {
    font-size: 1.275rem;
}
@media screen and (max-width: 599px) {
    .flash__image__container {
        display: block;
    }
}
