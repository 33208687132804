.akt {
    font-family: $Cairo;
    button {
        border-radius: 8px;
        font-size: 18px;
        @include tablet {
            font-size: 16px;
        }
    }
    .section__title {
        font-weight: 900;
        color: $akt-light-blue;
        font-size: 3.75rem;
        margin: 0;
        line-height: 1.15;
        text-transform: uppercase;
        @include tablet {
            font-size: 3rem;
        }
        @include phone {
            font-size: 2.5rem;
        }
    }
    .confidentiality {
        max-width: 1770px;
        margin: 0 auto;
        padding: 25px;
    }
}
.akt__sub__warning {
    p {
        font-weight: bold;
    }
}
.akt__sub__warning__title {
    text-align: center;
    color: $akt-dark-teal;
    font-weight: 900;
}
.akt__sub__warning__item {
    text-align: center;
    color: $akt-light-blue;
}
