.orderOnline {
	position: relative;
	&__container {
		max-width: 1280px;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 50px 120px 100px;
		@include phone {
			padding: 50px 25px 100px;
		}
	}
	&__title {
		font-family: $kansas;
		font-size: 38px;
		font-weight: 600;
		padding-bottom: 40px;
		@include phone {
			font-size: 26px;
		}
	}
	&__steps {
		display: flex;
		justify-content: space-between;
		margin-bottom: 65px;
		width: 100%;
		position: relative;
		&::after {
			content: "";
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%234A4A4A16' stroke-width='4' stroke-dasharray='15%2c 50' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
			position: absolute;
			top: 26px;
			left: 9%;
      height: 2px;
			width: 82%;
      display: block;
      text-align: center;
      margin: auto;
      z-index: -5;
			@include tablet {
				visibility: hidden;
			}
		}
			@include tablet {
				flex-direction: column;
				align-items: center;
				gap: 50px;
			}
	}
	&__step {
		max-width: 200px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 15px;
    z-index: 5;
		.step {
			&__number {
				margin: 0 25px 25px 25px;
        background-color: white;
        z-index: 5;
				@include tablet {
					margin: 0;
				}
			}
			&__title {
				font-family: $kansas;
				font-size: 18px;
				font-weight: 600;
				margin: 0;
			}
			&__text {
				font-family: $europa;
				font-family: 15px;
				margin: 0;
			}
		}
	}
}
