@import "../../../../../scss/parts/shared/_variables.scss";
$selected: rgba(159, 159, 159, 1);
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .images {
        img {
            max-width: 70px;
            object-fit: contain;

            &.disabled {
                filter: grayscale(100%);
                opacity: 0.3;
            }
        }
    }

    .props {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 10px;
    }

    .text__prop {
        background-color: #eee;
        color: black;
        padding: 15px;
        width: max-content;
        border: 1px solid #c4c4c4;
    }

    .prop {
        text-align: center;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
            outline: 1px solid #eee;
        }
    }

    .selected {
        outline: 1px solid #cecece;
        background-color: $akt-dark-teal;
        color: white;
    }

    .disabled {
        cursor: not-allowed;

        &:hover {
            outline: none;
        }
    }

    .crossed {
        color: $selected;
        background: linear-gradient(
            to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            red 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%
        );
    }
}
