.contact {
    display: flex;
    @include phone {
        flex-direction: column;
    }
    &__left {
        width: 45%;
        position: relative;
        @include phone {
            width: 100%;
        }
        &::after {
            content: "";
            background: rgba(2, 123, 170, 0.05);
            border-radius: 0px 0px 300px 0px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        &--title {
            background-color: #027baa;
            h3 {
                font-family: $Montserrat;
                font-size: 60px;
                font-weight: 900;
                color: #035164;
                text-transform: uppercase;
                margin: 0;
                padding: 50px 25px 50px 150px;
                @include tablet {
                    font-size: 45px;
                    padding: 50px;
                }
                @include phone {
                    text-align: center;
                    font-size: 35px;
                }
            }
        }
        &--info {
            padding: 50px 25px 50px 200px;
            @include tablet {
                padding: 50px;
            }
            @include phone {
                text-align: center;
                padding: 25px;
            }
            h6 {
                font-family: $Montserrat;
                font-weight: 900;
                font-size: 40px;
                color: $akt-dark-teal;
                margin: 10rem 0 2rem;
                @include tablet {
                    font-size: 35px;
                }
                @include phone {
                    margin: 3rem 0 1rem;
                    font-size: 30px;
                }
            }
            a {
                font-family: $Montserrat;
                font-weight: 700;
                font-size: 30px;
                color: black;
                @include tablet {
                    font-size: 25px;
                }
            }
            .medias {
                display: flex;
                gap: 20px;
                @include phone {
                    justify-content: center;
                }
                svg {
                    width: 50px !important;
                    height: 50px !important;
                    color: black !important;
                }
            }
        }
    }
    &__right {
        width: 55%;
        @include phone {
            width: 100%;
        }
        img {
            width: 100%;
            height: 450px;
            object-fit: cover;
            @include phone {
                display: none;
            }
        }
        &--form {
            padding: 50px;
            @include phone {
                padding: 25px;
            }
            .MuiFormControl-root {
                background-color: #e1e1e1;
                margin: 1rem 0;
                border-radius: 8px;
                .MuiInputLabel-animated {
                    margin-left: 16px;
                }
                .MuiInputBase-root {
                    margin: 16px;
                }
                .MuiInput-underline:before {
                    border-bottom: 0;
                }
                .MuiInput-underline:hover:not(.Mui-disabled):before {
                    border-bottom: 0;
                }
                .MuiInput-underline.Mui-focused:after {
                    border-bottom: 0;
                }
                .MuiInput-underline:after {
                    border-bottom: 0;
                }
            }
            .cta {
                background-color: black;
                text-transform: uppercase;
                border-radius: 8px;
                font-family: $Montserrat;
                font-size: 18px;
                font-weight: 700;
                color: white;
                padding: 13px 100px;
                float: right;
                margin-top: 16px;
                @include phone {
                    width: 100%;
                    float: unset;
                    font-size: unset;
                }
            }
        }
    }
}
.contact__top {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 152px);
}
.contact__background {
    position: absolute;
    left: 0;
    right: 0;
    top: 152px;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 152px);
    object-fit: cover;
}
.contact__container {
    position: relative;
    background-color: white;
    border-radius: 30px;
    left: 20%;
    padding: 40px;
    max-width: 750px;
    .MuiFormControl-fullWidth {
        margin-top: 20px;
    }
}
.contact__title {
    font-size: 1.5rem;
    font-family: $Roboto;
    font-weight: bold;
    &.faim {
        color: $mcf-blue;
    }
    &.Matcha {
        color: $gm-green;
    }
    &.Aktivation {
        color: $akt-light-blue;
    }
    &.Choko {
        color: $gc-brown;
    }
    &.Krisp {
        color: $gk-dark-beige;
    }
}
.contact__cta {
    margin-top: 25px;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    font-family: $Roboto;
    font-weight: bold;
    color: white;
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
        background-color: $gk-red;
    }
}
#contact__map {
    width: 100%;
    height: 300px;
}
@media screen and (max-width: 1410px) {
    .contact {
        min-height: calc(100vh - 103px);
    }
    .contact__background {
        top: 103px;
        height: calc(100vh - 103px);
    }
    .contact__top {
        min-height: calc(100vh - 103px);
    }
}
@media screen and (max-width: 1200px) {
    .contact__container {
        left: inherit;
        margin: 5px;
    }
    .contact__top {
        justify-content: flex-end;
    }
}

// Corporate

.contact {
    &__left {
        &--image {
            @include tablet {
                display: none;
            }
            @include phone {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 25px;
            }
            img {
                max-width: 700px;
                width: 100%;
                margin-top: 275px;
                @include phone {
                    margin-top: 0;
                    border-radius: 50px;
                }
            }
        }
    }
    &__right {
        &--content {
            margin: 100px;
            display: flex;
            flex-direction: column;
            gap: 40px;
            @include tablet {
                width: 67vw;
                transform: translate(-55%, 0);
            }
            @include phone {
                width: unset;
                transform: unset;
                margin: 50px 25px;
            }
        }
        &--title {
            font-family: $Montserrat;
            font-size: 40px;
            font-weight: 900;
            color: $akt-dark-teal;
            margin: 0;
        }
        &--text {
            font-family: $Montserrat;
            font-size: 20px;
            font-weight: 700;
            color: #484848;
            margin: 0;
            line-height: 1.5;
        }
        li {
            font-family: $Montserrat;
            font-size: 20px;
            font-weight: 500;
            color: #484848;
            padding-bottom: 30px;
            list-style-image: url("../../../assets/akt/checkmark.png");
            span {
                font-weight: 700;
            }
        }
        button {
            background: black;
            color: white;
            text-transform: uppercase;
            font-size: 1.125rem;
            padding: 18px 40px;
            font-weight: 700;
            font-family: $Montserrat;
            width: fit-content;
        }
    }
}
