.product {
	border-radius: 25px;
	display: flex;
	justify-content: center;
	margin: 10px;
	position: relative;
	&__container {
		border-radius: 25px;
		width: 100%;
		height: 315px;
		display: flex;
		flex-direction: column;
		justify-content: end;
		align-items: center;
		padding: 20px 25px;
		&:hover {
			width: calc(100% - 50px);
			height: 315px;
			position: absolute;
			justify-content: end;
			background: linear-gradient(
				180deg,
				rgba(74, 74, 74, 0) 0%,
				rgba(74, 74, 74, 0.08) 100%
			);
			transition: background-color 0.3s;
			.product__cta {
				transition: all 0.3s ;
				display: block;
				margin-top: 20px;
				height: 100%;
				padding: 1.25rem 1.75rem;
				overflow: inherit;
				max-height: 100%;
				@include tablet {
					display: none;
				}
			}
			@include tablet {
				height: 300px;
				position: unset;
				justify-content: space-between;
				background: rgba(74, 74, 74, 0.04);
			}
		}
		@include tablet {
			height: 300px;
		}
		@include phone {
			height: 275px;
		}
		&--box {
			background-color: rgba(74, 74, 74, 0.04);
			border: 1px solid rgba(74, 74, 74, 0.04);
		}
		&--snacks {
			border: 1px solid rgba(35, 35, 35, 0.08);
			background-color: #fff;
			transition: all 0.3s ;
			&:hover {
				width: calc(100% - 52px);
			}
		}
	}
	&__image {
		&--centered {
			width: 100%;
			height: auto;
			max-width: 210px;
			margin-bottom: 40px;
			@include tablet {
				margin-bottom: 20px;
			}
			@include phone {
				width: 80%;
			}
		}
		&--background {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__title {
		display: flex;
		font-family: $europa;
		font-size: 24px;
		font-weight: 700;
		margin: 0;
		margin-bottom: 5px;
		text-align: center;
		@include tablet {
			margin-bottom: 12px;
		}
	}
	&__subtitle {
		font-family: $europa;
		font-size: 15px;
		color: $grey90;
		margin: 0;
		text-align: center;
	}
	&__cta {
		max-height: 0%;
		padding: 0rem 1.75rem;		
		overflow: hidden;
		margin-top: 20px;
		width: 90%;
	}
}
.product__containerBackground {
	border-radius: 25px;
	background-color: rgba(74, 74, 74, 0.04);
	border: 1px solid rgba(35, 35, 35, 0.08);
	width: 100%;
	height: 315px;
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: center;
	padding: 20px 25px;
	&:hover {
		width: calc(100% - 50px);
		.cta {
			transition: all 0.3s ;
			display: block;
			margin-top: 20px;
			// height: 100%;
			padding: 1.25rem 1.75rem;
			overflow: inherit;
			max-height: 100%;
			@include tablet {
				display: none;
			}
		}
		@include tablet {
			position: unset;
			background: rgba(74, 74, 74, 0.04);
		}
	}
	@include tablet {
		height: 300px;
	}
	@include phone {
		height: 275px;
	}

	.title {
		display: flex;
		font-family: $europa;
		font-size: 24px;
		font-weight: 700;
		margin: 0;
		margin-bottom: 5px;
		text-align: center;
		color: white;
		@include tablet {
			margin-bottom: 12px;
		}
	}
	.subtitle {
		font-family: $europa;
		font-size: 15px;
		color: #ffffff90;
		margin: 0;
		text-align: center;
	}
	.cta {
		max-height: 0%;
		padding: 0rem 1.75rem;		
		overflow: hidden;
		margin-top: 20px;
		width: 90%;
	}
}

// ProductCta
.productCta {
	border-radius: 25px;
	display: grid;
	grid-column: span 2;
	justify-content: center;
	margin: 10px;
	position: relative;
	height: 357px;
	@include phone {
		grid-column: span 1;
		grid-row: span 2;
	}
	&__container {
		border-radius: 25px;
		width: 100%;
		height: 315px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 0;
		@include phone {
			flex-direction: column;
			height: 630px;
		}
		&--orange {
			background-color: $orange-background;
		}
		&--green {
			background-color: $green-background;
		}
	}
	&__left {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		gap: 16px;
		padding: 24px 48px;
		@include tablet {
			width: 50%;
		}
		@include tablet {
			width: unset;
			text-align: center;
			padding: 20px;
		}
	}
	&__section {
		font-family: $europa;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0;
		letter-spacing: 1px;
		&--orange {
			color: $orange;
		}
		&--green {
			color: $green;
		}
	}
	&__title {
		font-family: $kansas;
		font-size: 30px;
		font-weight: 600;
		margin: 0;
		@include phone {
			font-size: 26px;
		}
	}
	&__text {
		display: flex;
		align-items: center;
		gap: 10px;
		@include tablet {
			justify-content: center;
		}
		&--price {
			font-family: $europa;
			font-size: 16px;
			margin: 0;
		}
		&--subscription {
			font-family: $europa;
			font-size: 14px;
			color: $grey90;
			margin: 0;
		}
	}
	&__image {
		width: 100%;
		height: auto;
	}
}
