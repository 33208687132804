// RECIPES
.recipes__header {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 10px;
    font-weight: 600;
    font-size: 2rem;
    font-family: $Roboto;
    background-size: cover;
    background-position: center;
    position: relative;
    background-image: linear-gradient(rgba($color: black, $alpha: 0.5), rgba($color: black, $alpha: 0.5)),
        url("../../../assets/shared/recipeHeader.jpg");
}
.recipes__filters {
    font-family: $Roboto;
    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        justify-content: center;
        margin: 1rem;
    }

    li {
        margin: 2px;
    }
    .recipes__filter {
        font-weight: 500;
        font-size: 1.25rem;
    }
}
.recipes__wrapper {
    height: 100%;
    cursor: pointer;
    margin-bottom: 5px;
}
.recipes__item {
    margin: 5px;
    height: 98%;
    display: flex;
    flex-direction: column;
}
.recipes__image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.recipes__bottom {
    background-color: rgba($color: lightgrey, $alpha: 0.4);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}
.recipes__title {
    font-size: 2rem;
    font-family: $Roboto;
    margin: 5px 0;
    &.faim {
        color: $mcf-blue;
    }
    &.Matcha {
        color: $gm-green;
    }
    &.Aktivation {
        color: $akt-light-blue;
    }
    &.Choko {
        color: $gc-brown;
    }
    &.Krisp {
      color: $gk-dark-beige;
  }
}
.recipes__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $Roboto;
}
.recipes__level {
    text-transform: uppercase;
    color: #909090;
    font-weight: 700;
    font-size: 1.175rem;
}
.recipes__categories {
    .MuiChip-label {
        font-size: 1.1rem;
    }
}
// RECIPE
.recipe {
    font-family: $Roboto;
    min-height: calc(100vh - 152px);
    &.faim {
        background-color: rgba($color: $mcf-blue, $alpha: 0.1);
    }
    &.Matcha {
        background-color: rgba($color: $gm-green, $alpha: 0.1);
    }
    &.Aktivation {
        background-color: rgba($color: $akt-light-blue, $alpha: 0.1);
    }
    &.Choko {
        background-color: rgba($color: $gc-brown, $alpha: 0.1);
    }
    &.Krisp {
      background-color: rgba($color: $gk-dark-beige, $alpha: 0.1);
  }
}
.recipe__image {
    width: 100%;
    height: 30vh;
    object-fit: cover;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recipe__title {
    font-size: 3rem;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    background-color: rgba($color: #000000, $alpha: 0.5);
    padding: 10px;
    text-align: center;
}
.recipe__info {
    padding: 20px;
    margin-bottom: 56px;
}
.recipe__section__title {
    font-size: 2rem;
    font-weight: 500;
    &.faim {
        color: $mcf-blue;
    }
    &.Matcha {
        color: $gm-green;
    }
    &.Aktivation {
        color: $akt-light-blue;
    }
    &.Choko {
        color: $gc-brown;
    }
    &.Krisp {
      color: $gk-dark-beige;
  }
}
.recipe__ingredients {
    text-emphasis: center;
    p {
        // font-size: 1.5rem;
        display: inline;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
}
.recipe__step {
    line-height: 50px;
}

.recipe__nutrition__values {
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
      background-color: $gk-dark-beige;
    }
}

.recipe__step__number {
    color: white;
    font-weight: 900;
    padding: 10px;
    margin-right: 10px;
    opacity: 1;
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
      background-color: $gk-dark-beige;
    }
}
.recipe__step__text {
    p {
        display: inline;
    }
}
.recipe__prep {
    li {
        font-size: 1.25rem;
    }
    p {
        font-size: 1.25rem;
    }
}
.recipe__quickinfo {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: center;
    span {
        margin: 0px 10px;
    }
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
      background-color: $gk-dark-beige;
    }
}

// .container {
//     display: flex;
//     // justify-content: space-around;
//     align-items: flex-start;
//     // border: 2px dashed rgba(114, 186, 94, 0.35);
//     height: 100%;
//     // background: rgba(114, 186, 94, 0.05);
// }

// .shark-1 {
//     position: sticky;
//     top: 0;
// }
.recipe__ingredients__container {
    display: flex;
    // justify-content: space-around;
    align-items: flex-start;
    // border: 2px dashed rgba(114, 186, 94, 0.35);
    height: 100%;
    // background: rgba(114, 186, 94, 0.05);
}

.recipe__ingredients__bar {
    position: sticky;
    top: 0;
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
      background-color: $gk-dark-beige;
    }
}

.recipe__colors {
    &.faim {
        background-color: $mcf-blue-low-opacity;
    }
    &.Matcha {
        background-color: $gm-green-low-opacity;
    }
    &.Aktivation {
        background-color: $akt-light-blue-low-opacity;
    }
    &.Choko {
        background-color: $gc-brown-low-opacity;
    }
    &.Krisp {
      background-color: $gk-beige-low-opacity;
    }
}
