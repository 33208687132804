.home, .order, .howOrder {
    .how {
        position: relative;
        &__container {
            max-width: 1280px;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 50px 120px 100px;
            @include phone {
                padding: 50px 25px 100px;
            }
        }
        &__title {
            font-family: $kansas;
            font-size: 54px;
            font-weight: 600;
            @include phone {
                font-size: 32px;
            }
        }
        &__steps {
            display: flex;
            justify-content: space-between;
            margin-bottom: 65px;
            width: 100%;
            position: relative;
            &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%234A4A4A16' stroke-width='4' stroke-dasharray='15%2c 50' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
                // background-color: red;
                // border-top: 0px solid #4A4A4A;
                // border-style: dashed;
                position: absolute;
                top: 26px;
                left: 9%;
                height: 2px;
                width: 82%;
                display: block;
                text-align: center;
                margin: auto;
                @include tablet {
                    visibility: hidden;
                }
            }
            @include tablet {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }
        }
        &__step {
            max-width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            z-index: 5;
            .step {
                &__number {
                    margin: 0 25px 25px 25px;
                    padding: 0;
                    width: 56px;
                    height: 56px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    z-index: 5;
                    @include tablet {
                        margin: 0;
                    }
                }
                &__title {
                    font-family: $kansas;
                    font-size: 18px;
                    font-weight: 600;
                    margin: 0;
                }
                &__text {
                    font-family: $europa;
                    font-family: 15px;
                    margin: 0;
                }
            }
        }
        &::after {
            content: url("../../../../assets/bs/poivron.png");
            position: absolute;
            top: 0;
            right: 0;
            @include tablet {
                top: 50%;
            }
            @include phone {
                visibility: hidden;
            }
        }
    }
}
