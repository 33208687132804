.slider {
  position: relative;
  width: 100%;
  max-width: 1280px;
  height: 300px;
  &__slider1, &__slider2, &__slider3 {
    width: 100%;
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    top: 0;
    border-radius: 25px;
  }
  &__title {
    position: absolute;
    bottom: 50px;
    left: 50px;
    color: white;
    font-family: $europa;
    font-size: 30px;
    font-weight: 700;
    margin: 0;
  }
  &__subtitle {
    position: absolute;
    bottom: 25px;
    left: 50px;
    color: white;
    font-family: $europa;
    font-size: 15px;
    margin: 0;
  }
}