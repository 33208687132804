.table__container {
    table {
        // border-collapse: separate;
        border-spacing: 2px;
    }
    .MuiTableCell-root {
        text-align: center;
        // border: none;
    }
    .MuiTableCell-body {
        margin: 0 2px;
        background-color: #fff;
    }
    th {
        font-size: 15px;
        padding: 0;
        padding-bottom: 10px;
    }

    .column__sorted {
        cursor: pointer;
        font-weight: bold;
    }

    // tbody > tr {
    //     border-left: 4px solid blue;
    // }
}
