.products {
    max-width: 1280px;
    padding: 75px 120px;
    margin: auto;
    @include tablet {
        padding: 50px 25px;
    }
    &__container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-rows: 1fr;
        width: 100%;
        gap: 25px;
        @include tablet {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-auto-rows: 1fr;
            grid-auto-flow: dense;
        }
        @include phone {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            grid-auto-rows: 1fr;
        }
    }
}
.order {
    .products {
        padding: 50px 120px 75px;
        @include tablet {
            padding: 25px;
        }
        &__size {
            display: flex;
            justify-content: center;
            gap: 32px;
            margin-bottom: 30px;
            &--container {
                display: flex;
                gap: 16px;
                padding: 25px;
                border-radius: 16px;
                border: 1px solid rgba(74, 74, 74, 0.24);
                cursor: pointer;
                &.selected {
                    background: $orange-linear;
                    pointer-events: none;
                    .products__size--text {
                        h6 {
                            color: white;
                        }
                        p {
                            color: #ffffff80;
                          b {
                            color: white;
                          }
                        }
                    }
                    .products__size--image {
                      background-color: #ffffff20;
                      svg {
                        color: white !important;
                      }
                    }
                }
            }
            &--image {
                height: 50px;
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $orange-background;
                border-radius: 50px;
                svg {
                    color: $orange !important;
                    stroke-width: 0.3px;
                }
            }
            &--text {
                h6 {
                    font-family: $kansas;
                    font-size: 20px;
                    font-weight: 600;
                    margin: 0 0 7px 0;
                }
                p {
                    font-family: $europa;
                    font-size: 14px;
                    margin: 0;
                    color: $grey;
                    b {
                        color: black;
                    }
                }
            }
        }
    }
}
