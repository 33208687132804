.gk {
    // hero
    .hero {
        height: calc(100vh - 152px);
        position: relative;
        &__content {
            padding: 100px 0 0 100px;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            &--text {
                & > * {
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .hero__1 {
            font-size: 4rem;
        }
        .hero__2 {
            font-size: 1.5rem;
            background-color: rgba($color: white, $alpha: 0.5);
            display: inline-block;
        }
        .hero__background {
            position: absolute;
            top: 0;
            width: 100%;
            height: calc(100vh - 152px);
            object-fit: cover;
            z-index: -5;
        }
        .hero__cta {
            width: min-content;
            padding: 0.8rem 2rem;
        }
        @media screen and (max-width: 959px) {
            // .hero__background {
            //     display: none;
            // }
            .hero__background__mobile {
                display: block;
            }
            .hero {
                align-items: flex-end;
                justify-content: center;
            }
        }
        @media screen and (max-width: 1410px) {
            .hero {
                height: calc(100vh - 104px);
            }
        }
        @media screen and (max-width: 599px) {
            .hero__container {
                .hero__1 {
                    font-size: 4rem;
                }
            }
            .hero__content {
                padding: unset;
                width: 85%;
                margin: 1rem auto;
            }
            // .hero__background {
            //     filter: blur(3px);
            // }
        }   
    }
  // features
  .features {
    text-align: center;
    padding: 75px;
    .features__pc {
      width: 75%;
      margin: 0 auto;
      display: inherit;
    }
    button {
      background-color: $gk-red;
      color: white;
      font-weight: bold;
      border-radius: $gk-radius;
      border: 1px solid $gk-red;
      font-size: 1.25rem;
      padding: 10px 30px;
      &:hover {
        background-color: $gk-dark-red;
        border: 1px solid $gk-dark-red;
      }
    }
  }
  .features__mobile {
    display: none;
  }
  @media screen and (max-width: 1279px) { 
    .features {
      padding: 50px;
      .features__pc {
        display: none;
      }
    }
    .features__mobile {
      width: 100%;
      display: block !important;
    }
  }
  @media screen and (max-width: 599px) {
    .features {
      padding: 25px;
    }
  }
   // use
   .use {
    max-width: 100%;
    overflow: hidden;
    background-color: $gk-light-beige;
    img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
  .use__background {
    background: url('../../../assets/gk/gokrisp-use-background.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
.use__container {
    padding: 30px;
  }
  .use__1 {
    font-size: 1.5rem;
    font-weight: 100;
    color: black;
    display: block;
    padding: 0 15px;
  }
  .use__2 {
    font-size: 2rem;
    font-weight: 500;
    color: black;
    display: block;
    padding: 0 15px;
  }
  .use__3 {
    font-size: 1.25rem;
    font-weight: 400;
    color: black;
    display: block;
    padding: 15px;
  }
  .use__cta {
    margin-top: 40px;
    margin-left: 15px;
    background-color: $gk-red;
    color: white;
    font-weight: bold;
    border-radius: $gk-radius;
    border: 1px solid $gk-red;
    font-size: 1.25rem;
    padding: 10px 30px;
    &:hover {
      background-color: $gk-dark-red;
      border: 1px solid $gk-dark-red;
    }
  }
  @media screen and (max-width: 959px) {
    .use {
      .MuiGrid-item {
        width: 100%;
      }
    }
    .use__left__container {
      height: 150px;
    }
    .use__left {
        width: 100%;
    }
    .use__container {
        height: inherit;
        padding: 30px 0;
        max-width: 85%;
        margin: auto;
    }
  }
  // SOCIALS
  .socials {
    background-color: $gk-dark-beige;
  }
  .socials__container {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .socials__title {
      color: black;
      font-size: 1.75rem;
      font-weight: 600;
      padding: 50px 0;
      margin: 0;
  }
  .socials__icons {
      img {
          margin: 0px 10px;
      }
      a {
          display: inline;
      }
  }
  @media screen and (max-width: 748px) { 
      .socials__container {
        flex-direction: column;
        padding: 20px;
      }
      .socials__title {
        padding: 0;
      }
      .socials__packs {
        width: 100%;
      }
  }
  // PRODUCTS
  .products {
    margin: 100px;
  }
  .promo__desktop {
      img {
          max-width: 100%;
      }
  }
  .promo__mobile {
      display: none;
  }
  .products__title {
      &--1 {
        text-align: center;
        display: block;
        font-size: 2.2rem;
    }
    &--2 {
        text-align: center;
        display: block;
        font-size: 3rem;
        font-weight: 700;
      }
  }
  .products__subtitle {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: $gc-brown;
    display: block;
    margin: 30px 0px 60px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    svg {
        padding-left: 1rem;
        color: black !important;
    }
  }
  .products__container--item {
    display: flex;
    height: 100%;
  }
  .products__item {
    display: flex;
    position: relative;
    border-radius: 3px;
    margin-top: 30px;
    &--1 {
        background-color: #D2CDB6;
        &::after {
            background-color: #5C4E3F;
        }
    }
    &--2 {
        background-color: #932428;
        &::after {
            background-color: #E24553;
        }
    }
    &--3 {
        background-color: #EDBE88;
        &::after {
            background-color: #D59450;
        }
    }
    &--4 {
        background-color: #764636;
        &::after {
            background-color: #B8896F;
        }
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 5px;
        width: 100%;
        border-radius: 3px;
    }
  }
  .products__left {
    width: 50%;
    position: relative;
    padding: 2rem 0;
    margin-bottom: 1rem;
    .products__image {
        position: absolute;
        top: -20px;
        display: block;
        width: 100%;
        max-width: 325px;
        transform: rotate(-20deg);
    }
    .products__ingredients {
        position: absolute;
        bottom: -20px;
        padding: 1rem 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #E24553;
        font-size: 0.8rem;
        font-weight: 600;
        svg {
            color: #E24553 !important;
        }
    }
}
.products__right {
    width: 50%;
    padding: 2rem 2rem 2rem 0   ;
    .products__name--container {
        display: flex;
    }
    .products__name {
        font-size: 1.7rem;
        font-weight: 700;
        margin: 0;
        padding: 0;
        &--white {
            color: white;
        }
        &--black {
            color: black;
        }
    }
    .products__info {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 40px;
        &--white {
            color: white;
        }
        &--black {
            color: black;
        }
    }
}
.products__cta1, .products__cta2 {
    border-radius: 3px;
    border: none;
    font-size: 1.25rem;
    padding: 10px 15px;
    width: 48%;
}
.products__cta1 {
    background-color: $gk-red;
    color: white;
    font-weight: 700;
    &:hover {
      background-color: $gk-dark-red;
    }
}
.products__cta2 {
    background-color: $gc-gold;
    color: white;
    font-weight: 500;
}
@media screen and (max-width: 959px) {
    .products {
        max-width: 85%;
        margin: auto;

    }
    
}
  @media screen and (max-width: 699px) {
    .products__item {
        flex-direction: column-reverse;
    }
    .products__right {
        width: 100%;
        padding: unset;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1rem;
        & > * {
            margin: 0 2rem;
        }
    }
    .products__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 1rem 0;
        .products__ingredients {
            position: unset;
        }
    }
    .products__image {
        position: unset !important;
        transform: rotate(0) !important;
    }
    .products__top {
        img {
            margin: auto;
        }
        flex-direction: column;
    }
  }
  @media screen and (max-width: 320px) {
      .products__title {
          font-size: 3.5rem;
      }
  }
  // SAMPLE
  .sample {
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 25px;
    img {
        width: 100%;
    }
  }
  .sample__container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px;
  }
  .sample__title {
      font-size: 3rem;
      font-weight: 500;
      color: black;
      display: block;
      padding: 15px;
  }
  .sample__text {
      font-size: 1.25rem;
      font-weight: 400;
      color: black;
      display: block;
      padding: 15px;
  }
  .sample__text__item {
    font-size: 1.25rem;
    font-weight: 600;
    color: black;
    display: block;
    padding: 15px;
  }
  .sample__cta {
      margin-top: 15px;
      background-color: $gk-red;
      color: white;
      font-weight: bold;
      border-radius: $gk-radius;
      border: 1px solid $gk-red;
      font-size: 1.25rem;
      padding: 10px 30px;
      display: block;
      &:hover {
        background-color: $gk-dark-red;
        border: 1px solid $gk-dark-red;
      }
  }
  @media screen and (max-width: 1060px) {
      .sample__container {
          height: inherit;
      }
  }
  @media screen and (max-width: 959px) {
    .sample__container {
        padding: 30px 0;
        max-width: 85%;
        margin: auto;
    }
  }
  // VALEURS
  .valeurs {
    background-color: $gk-light-beige;
    padding: 75px 10px;
    .MuiTabs-root {
        background-color: $gk-dark-beige;
    }
    .MuiTab-textColorInherit {
        color: black;
        font-weight: bold;
    }
    .Mui-selected {
        color: $gk-dark-red;
    }
    .MuiTabs-indicator {
        background-color: $gk-dark-red;
        height: 4px;
    }
    .PrivateTabIndicator-colorSecondary-3 {
        background-color: $gk-dark-red;
    }
    .PrivateTabIndicator-root-1 {
        height: 4px;
    }
  }
  .valeurs__title {
      padding-bottom: 50px;
      text-align: center;
      font-size: 5rem;
      font-weight: 700;
      color: black;
      margin-block-end: 0;
      margin-block-start: 0;
  }
  .valeurs__tabs {
      max-width: 70%;
      margin: auto;
  }
  .valeurs__left, .valeurs__right {
      padding: 30px;
  }
  .valeurs__left {
      background-color: white;
      text-align: center;
      width: 100%;
  }
  .valeurs__right {
      background-color: white;
      width: 100%;
      text-align: center;
  }
  .valeurs__gk {
      max-width: 100%;
  }
  .valeurs__nv {
      max-width: 100%;
  }
  @media screen and (max-width: 599px) {
      .valeurs__tabs {
          max-width: 95%;
      }
  }
  // ADVANTAGES
  .advantages {
    padding: 25px 100px;
    &__container {
        align-items: center;
    }
    &__title {
        padding-bottom: 3rem;
        &--1 {
            font-size: 2.25rem;
            margin: 0;
            padding: 0;
        }
        &--2 {
            font-size: 3rem;
            font-weight: 600;
            margin: 0;
            padding: 0;
        }
    }
    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 2rem 0;
        & > * {
            margin-right: 2rem;
            span {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 1.3rem;
                img {
                    margin-right: 1rem;
                    width: 30px;
                }
            }
        }
    }
    &__image {
        width: 100%;
    }
    @media screen and (max-width: 959px) {
        padding: 25px 0;
        width: 85%;
        margin: auto;
        .advantages {
            &__image {
                max-width: 500px;
                display: block;
                margin: 2rem auto 0;
            }
        }
    }
  }
  // SHIPPING
  .shipping {
    background-color: $gk-red;
  }
  .shipping__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 80%;
    margin: 0 auto;
  }
  .shipping__pack {
    position: absolute;
    bottom: -40px;
    left: 0;
  }
  .shipping__title {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    svg {
      fill: white;
    }
  }
  .shipping__logo__fake {
    visibility: hidden;
  }
  .shipping__logo {
    width: 5%;
  }
  @media screen and (max-width: 959px) {
    .shipping__pack, .shipping__logo, .shipping__logo__fake {
      display: none;
    }
    .shipping__container {
      justify-content: center;
    }
}
// testimonials
.testimonials {
  background: url('../../../assets//gk/gk-testimonials-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $gk-light-beige;
  padding: 40px 90px 0 90px;
}
.testimonials__container {
  display: flex;
  justify-content: center;
  align-items: left;
  height: 100%;
  flex-direction: column;
}
.testimonials__text {
  display: block;
  font-weight: 600;
  font-size: 4rem;
  @media screen and (max-width:600px) {
    font-size: 30px;
  }
}
.testimonials__cta {
  margin-top: 40px;
  margin-left: 15px;
  background-color: $gk-red;
  color: white;
  font-weight: bold;
  border-radius: $gk-radius;
  border: 1px solid $gk-red;
  font-size: 1.25rem;
  padding: 10px 30px;
  &:hover {
    background-color: $gk-dark-red;
    border: 1px solid $gk-dark-red;
  }
}
.testimonials__right {
  position: relative;
  height: 75vh;
  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        div {
          height: 100%;
          div {
            height: 100%;
            div {
              height: 100%;
              img {
                margin: auto;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 959px) {
  .testimonials__container {
    text-align: center;
    margin-bottom: 60px;
    display: block;
  }
}
// newsletter
.newsletter {
  max-width: 100%;
  overflow: hidden;
  img {
      height: 100%;
  }
}
.newsletter__container {
    background: url('../../../assets/gk/gokrisp-newsletter-background.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: $gk-dark-beige;
}
.newsletter__1 {
    font-size: 1.75rem;
    font-weight: 100;
    color: black;
    display: block;
    padding: 0 15px;
}
.newsletter__2 {
    font-size: 2rem;
    font-weight: 600;
    color: black;
    display: block;
    padding: 0 15px;
}
.newsletter__3 {
    font-size: 1rem;
    font-weight: 400;
    color: black;
    display: block;
    padding: 30px 15px;
}
@media screen and (max-width: 959px) {
    .newsletter__left {
        position: relative;
        left: -115px;
    }
    .newsletter__container {
        height: inherit;
    }
}
@media screen and (max-width: 599px) {
    .newsletter__left {
        left: -225px;
    }
}
  // FAQ 
  .faq {
    padding: 50px 0 50px 100px;
  }
  .faq__title {
      font-size: 2rem;
      font-weight: 500;
      color: black;
  }
  .faq__container {
      margin: auto;
      display: flex;
      .MuiPaper-elevation1 {
          box-shadow: none;
      }
      .MuiIconButton-label {
          background-color: $gk-red;
      }
      .MuiAccordionDetails-root {
          p {
              color: black !important;
              font-weight: 100;
          }
      }
      .MuiSvgIcon-root {
          color: white;
      }
      .MuiAccordionSummary-root.Mui-expanded {
          min-height: initial;
      }
      .MuiAccordionSummary-content.Mui-expanded {
          margin: initial;
      }
      p {
          color: black;
          font-weight: bold;
          font-size: 16px;
      }
      .Mui-expanded {
        p {
          color: $gk-red;
        }
      }
  }
  @media screen and (max-width: 949px) {
      .faq {
        padding: 100px 10px;
      }
      .faq__bg {
          display: none;
      }
  }
  @media screen and (max-width: 599px) {
      .faq {
          padding: 100px 10px;
      }
  }
  // FOOTER
  .footer {
    background-color: $gk-light-beige;
    padding: 100px;
  }
  .footer__title {
      font-size: 1.5rem;
      color: black;
      font-weight: 600;
      text-align: center;
      margin-block-end: 2em;
      margin-block-start: 0;
  }
  .footer__container {
      display: flex;
      max-width: 80%;
      margin: auto;
      justify-content: center;
      align-items: center;
      img {
          max-width: 50%;
          margin: 0px 40px;
      }
  }
  @media screen and (max-width: 1279px) {
      .footer {
          padding: 50px 5px;
      }
      .footer__container {
          flex-direction: column;
          text-align: center;
          img {
            margin: 20px;
          }
      }
  }
  @media screen and (max-width: 599px) {
      .footer__container {
          img {
              margin: 10px 0px;
          }
      }
  }
  // FOOTER NAV
  .footernav {
    background-color: $gk-dark-beige;
    color: black;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 20px;
    a {
        margin: 20px;
    }
  }
  .footernav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .footernav__text {
    font-weight: 100;
  }
  .footernav__highlight {
    color: $gk-red;
  }
  @media screen and (max-width: 959px) {
      a {
          display: block;
      }
  }
}