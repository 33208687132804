.progressOrder {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 85px;
    z-index: 50;
    background-color: white;
    filter: drop-shadow(0px -4px 24px rgba(0, 0, 0, 0.08));
    &__container {
        max-width: 1280px;
        padding: 0 25px;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__step {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 80%;
        &--image {
            width: 40px;
            height: 40px;
            background-color: $green-background;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 20px;
                height: 20px;
            }
        }
        &--text {
            font-family: $europa;
            font-size: 16px;
            white-space: nowrap;
        }
        .MuiBox-root {
            margin-left: 50px;
            // margin-right: 10px;
            display: flex;
            align-items: center;
            gap: 20px;
            .subtotal {
                display: flex;
                align-items: center;
                gap: 8px;
                border-left: solid 1px $grey20;
                padding-left: 20px;
                .title {
                    font-family: $europa;
                    font-size: 15px;
                    color: $grey;
                    opacity: 0.8;
                    margin: 0;
                  }
                  .price {
                    font-family: $europa;
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0;
                }
            }
            .MuiLinearProgress-root {
                width: 100%;
                height: 12px;
                border-radius: 20px;
                background-color: #d9d9d9;
                .MuiLinearProgress-barColorPrimary {
                    background: linear-gradient(90deg, #52923c 0%, #9ec442 100%);
                    border-radius: 25px;
                }
            }
        }
        &--percentage {
            font-family: $europa;
            font-size: 18px;
            font-weight: 700;
            margin: 0;
        }
    }
}
