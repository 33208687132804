@import "../../.././../../scss/parts/shared/_variables.scss";
.container {
    .quantity {
        display: inline-block;
        width: 35px;
        text-align: center;
    }

    button {
        background-color: $akt-dark-teal;
        color: white;
        border: 4px solid $akt-dark-teal;
        width: 35px;
        &.left {
            box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.25);
            border-radius: 100px 0px 0px 100px;
        }

        &.right {
            box-shadow: 0px 0px 5.02415px -1.25604px rgba(0, 0, 0, 0.25);
            border-radius: 0px 31.401px 31.401px 0px;
        }
    }
}
