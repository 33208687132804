.mcf {
  // HERO
  .hero {
    height: calc(100vh - 152px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../../assets//mcf/hero.jpg") no-repeat center center;
    background-size: cover;
  }
  .hero__container {
    position: relative;
    left: -250px;
    span {
      display: block;
      position: relative;
      img {
        position: absolute;
        bottom: -38px;
      }
    }
    .hero__1 {
      color: $mcf-lightblue;
      font-size: 1.375rem;
      font-weight: 700;
    }
    .hero__2 {
      color: $mcf-green;
      font-size: 4.5rem;
      font-weight: 900;
    }
    .hero__3 {
      color: $mcf-blue;
      font-size: 1.375rem;
    }
    .hero__cta {
      margin-top: 50px;
    }
    .hero__cta1 {
      border-radius: $mcf-radius;
      border: none;
      color: white;
      font-size: 1.5rem;
      font-weight: 800;
      padding: 6px 20px;
    }
    .hero__cta1 {
      background-color: $mcf-green;
    }
  }
  @media screen and (max-width: 1279px) {
    .hero {
      justify-content: inherit;
    }
    .hero__container {
      padding: 10px;
      left: unset;
      span {
        img {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 1410px) {
    .hero {
      height: calc(100vh - 104px);
    }
  }
  @media screen and (max-width: 599px) {
    .hero__container {
      z-index: 2;
    }
    .hero {
      background: none;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: url("../../../assets//mcf/hero.jpg") no-repeat 55% center;
        background-size: cover;
        z-index: 1;
        filter: blur(3px);
      }
    }
  }
  // INTRO
  .intro {
    background-color: #dadede;
    display: flex;
    justify-content: center;
  }
  .intro__container {
    position: relative;
    top: -70px;
    left: 100px;
  }
  .intro__content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
  }
  .intro__video {
    border: 6px solid $mcf-darkgreen;
    border-radius: $mcf-radius;
    position: relative;
    left: -100px;
    overflow: hidden;
  }
  .intro__info {
    span {
      display: block;
    }
    .intro__1 {
      font-size: 3.75rem;
      color: white;
      font-weight: 900;
    }
    .intro__2 {
      font-size: 11.25rem;
      color: white;
      font-weight: 900;
      line-height: 150px;
    }
    .intro__3 {
      font-size: 2.25rem;
      color: $mcf-green;
      font-weight: 500;
      white-space: nowrap;
    }
    .intro__cta {
      color: white;
      font-size: 1.75rem;
      font-weight: 700;
      border-radius: $mcf-radius;
      border: none;
      padding: 6px 20px;
      background-color: $mcf-green;
      margin-top: 25px;
    }
  }
  @media screen and (max-width: 1279px) {
    .intro {
      padding: 30px;
      background-color: $mcf-blue;
    }
    .intro__container {
      top: unset;
      left: unset;
      img {
        display: none;
      }
    }
    .intro__content {
      position: relative;
      flex-direction: column;
    }
    .intro__video {
      left: unset;
      iframe {
        max-width: 100%;
      }
    }
  }
  // CLIENTS
  .clients {
    background-color: white;
    padding: 40px;
  }
  .clients__title1 {
    font-size: 3rem;
    font-weight: 900;
    color: $mcf-lightblue;
    text-align: center;
  }
  .clients__title2 {
    font-size: 3.75rem;
    font-weight: 900;
    color: $mcf-blue;
    text-align: center;
  }
  .clients__container {
    display: flex;
    max-width: fit-content;
    margin: auto;
    iframe {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 1279px) {
    .clients {
      padding: 50px 5px;
    }
    .clients__container {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 599px) {
    .clients__container {
      width: auto;
    }
  }
  // CTA
  .cta {
    padding: 50px;
    background-color: $mcf-blue;
    background-size: cover;
    text-align: center;
  }
  .cta__title {
    color: white;
    font-size: 3.75rem;
    font-weight: 900;
  }
  .cta__text1,
  .cta__text2 {
    color: white;
    display: block;
    font-size: 2.25rem;
    font-weight: 500;
    margin: 10px;
  }
  .cta__cta {
    color: white;
    font-size: 1.875rem;
    font-weight: 800;
    border-radius: $mcf-radius;
    border: none;
    padding: 6px 25px;
    background-color: $mcf-green;
    margin: 25px;
  }
  @media screen and (max-width: 599px) {
    .cta {
      padding: 10px;
    }
  }
  // INGREDIANTS
  .ingred {
    background: url("../../../assets//mcf/ingredients-bg.jpg") no-repeat center
      center;
    background-size: cover;
    padding: 100px;
    position: relative;
  }
  .ingred__img {
    position: absolute;
    right: 0;
    bottom: 40px;
  }
  .ingred__container {
    width: 80%;
    margin: auto;
  }
  .ingred__title {
    color: $mcf-green;
    font-size: 3.75rem;
    font-weight: 900;
  }
  .ingred__text {
    color: $mcf-blue;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .ingred__ingred {
    margin-top: 25px;
    span {
      color: $mcf-blue;
      font-size: 1.8125rem;
      font-weight: 600;
      display: block;
    }
  }
  @media screen and (max-width: 1279px) {
    .ingred {
      padding: 50px 0px;
      text-align: center;
    }
    .ingred__img {
      position: relative;
      right: unset;
      bottom: unset;
    }
  }
  // PRODUCTS
  .products {
    background-color: white;
    background: url("../../../assets/mcf/products-bg.jpg") no-repeat center
      bottom;
    background-size: contain;
    border-bottom: 10px solid $mcf-green;
  }
  .products__container {
    width: 70%;
    margin: auto;
    padding-bottom: 100px;
  }
  .products__title {
    padding: 60px;
    margin: 0;
    color: $mcf-blue;
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
  }
  .products__grid {
    justify-content: center;
  }
  .products__item {
    border: 10px solid $mcf-blue;
    border-radius: $mcf-radius;
    background-color: $mcf-blue;
    &:hover {
      transform: scale(1.05);
    }
  }
  .products__top {
    background-color: $mcf-pastel-lightblue;
    padding: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    &.green {
      background-color: $mcf-pastel-lightgreen;
    }
  }
  .products__item__title {
    margin: 0;
    color: $mcf-blue;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    display: block;
  }
  .products__item__subtitle {
    margin: 0;
    color: $mcf-blue;
    font-size: 1.875rem;
    font-weight: 700;
    text-align: center;
    display: block;
  }
  .products__middle {
    background-image: linear-gradient(
      $mcf-pastel-darkblue-top,
      $mcf-pastel-darkblue-bottom
    );
    padding: 40px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: center;
    img {
      margin: 20px;
      max-width: 40%;
    }
    &.green {
      background-image: linear-gradient(
        $mcf-pastel-darkgreen-top,
        $mcf-pastel-darkgreen-botttom
      );
    }
  }
  .products__best {
    color: black;
    background-color: #ffb800;
    padding: 10px 20px;
    position: absolute;
    border-radius: $mcf-radius;
    font-weight: 800;
    top: -25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    white-space: nowrap;
  }
  .products__price,
  .products__price__cents,
  .products__price__pack {
    color: white;
  }
  .product__price,
  .products__price {
    font-size: 5rem;
    font-weight: 600;
  }
  .product__price {
    position: relative;
    left: 25px;
    display: block;
  }
  .products__price__cents {
    font-size: 2.5rem;
    font-weight: 600;
  }
  .products__price__pack {
    font-weight: 600;
    font-size: 1.25rem;
    position: relative;
    left: -60px;
    top: -20px;
  }
  .products__savings {
    font-weight: 600;
    font-size: 0.9375rem;
    display: block;
    color: white;
    position: relative;
    top: -15px;
  }
  .products__total__price,
  .products__total__price__cents,
  .products__total__price__pack {
    color: white;
  }
  .product__total__price,
  .products__total__price {
    font-size: 2.5rem;
    font-weight: 600;
  }
  .product__total__price {
    position: relative;
    left: 10px;
    display: block;
  }
  .products__total__price__cents {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .products__total__price__pack {
    font-weight: 600;
    font-size: 0.625rem;
    position: relative;
    left: -25px;
    top: -10px;
  }
  .product__monthly__packs {
    color: $mcf-blue;
    font-weight: 700;
  }
  .product__monthly__packs__2 {
    color: white;
    font-weight: 700;
    display: block;
  }
  .products__cta {
    color: white;
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 25px;
    padding: 10px;
  }
  @media screen and (max-width: 1279px) {
    .products__price__pack {
      left: -50px;
    }
    .products__total__price__pack {
      left: -25px;
    }
  }
  @media screen and (max-width: 599px) {
    .products__title {
      padding: 60px 10px;
    }
    .products__container {
      width: 90%;
    }
  }
  // SHIPPING
  .shipping {
    background-color: $mcf-blue;
    h3 {
      font-size: 2.75rem;
      font-weight: 900;
      color: white;
      text-align: center;
      padding: 40px;
      margin: 0;
    }
  }
  // USE CASE
  .use {
    max-height: fit-content;
  }
  .use__left {
    img {
      max-width: 100%;
    }
  }
  .use__right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .use__right__container {
    padding: 40px;
    width: 80%;
  }
  .use__title {
    // padding-top: 60px;
    margin: 0;
    color: $mcf-blue;
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
  }
  .use__subtitle {
    font-size: 1.875rem;
    font-weight: 400;
    color: $mcf-blue;
    display: block;
    text-align: center;
  }
  .use__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 20px;
    span {
      font-size: 1.125rem;
      font-weight: 600;
      word-wrap: none;
    }
  }
  .use__disclaimer {
    font-family: $Roboto;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    margin: 50px 5px 5px 5px;
  }
  .use__cta {
    color: white;
    font-size: 1.5rem;
    font-weight: 800;
    border-radius: $mcf-radius;
    border: none;
    padding: 6px 25px;
    background-color: $mcf-blue;
    margin: auto;
    display: block;
  }
  @media screen and (max-width: 1400px) {
    .use__item {
      img {
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 599px) {
    .use__items__mobile__center {
      display: flex;
      justify-content: center;
    }
  }
  // TESTIMONIALS
  .testimonials {
    background: url("../../../assets/mcf/testimonials-bg.png") no-repeat center
      left;
    background-size: cover;
    margin-top: -4px;
  }
  .testimonials__container {
    width: 70%;
    margin: auto;
    padding-bottom: 40px;
  }
  .testimonials__title {
    padding: 40px;
    margin: 0;
    color: white;
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
  }
  .testimonials__top {
    background-color: white;
    padding: 20px;
    span {
      color: black;
      font-size: 1.375rem;
      font-weight: 500;
      text-align: center;
      display: block;
      padding: 40px;
    }
    img {
      display: block;
      border-radius: 50%;
      margin: 0 auto -80px auto;
      max-width: 121px;
    }
  }
  .testimonials__bottom {
    background-color: black;
    border-bottom-left-radius: $mcf-radius;
    border-bottom-right-radius: $mcf-radius;
    span {
      color: white;
      font-size: 1.75rem;
      font-weight: 900;
      text-align: center;
      display: block;
      padding: 80px 0px 40px 0px;
    }
  }
  @media screen and (max-width: 599px) {
    .testimonials__title {
      padding: 40px 10px;
    }
  }

  // SOCIALS
  .socials {
    background: url("../../../assets/shared/socials.png") no-repeat center left;
    background-color: #333;
    background-size: contain;
  }
  .socials__container {
    width: 80%;
    margin: auto;
  }
  .socials__title {
    color: white;
    font-size: 2.625rem;
    font-weight: 900;
    padding: 50px 0;
    margin: 0;
  }
  .socials__icons {
    float: right;
    img {
      margin: 0px 10px;
    }
    a {
      display: inline;
    }
  }
  @media screen and (max-width: 1279px) {
    .socials {
      background-size: cover;
    }
    .socials__icons {
      display: block;
      float: unset;
    }
  }
  // NEWSLETTER
  .newsletter {
    background: url("../../../assets/mcf/newsletter.jpg");
    background-position: center;
    background-size: cover;
    background-position: center;
    padding: 150px 20px;
  }
  .newsletter__title,
  .newsletter__text {
    color: white;
    display: block;
    text-align: center;
  }
  .newsletter__title {
    font-size: 3.75rem;
    font-weight: 700;
  }
  .newsletter__text {
    font-size: 1.5rem;
    font-weight: 600;
  }
  @media screen and (max-width: 599px) {
    .newsletter {
      padding: 100px 10px;
    }
  }
  // FAQ
  .faq {
    padding: 100px;
  }
  .faq__title {
    font-size: 3.75rem;
    font-weight: 900;
    color: $mcf-blue;
    text-align: center;
  }
  .faq__container {
    width: 80%;
    margin: auto;
    display: flex;
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
    .MuiIconButton-label {
      background-color: $mcf-green;
    }
    .MuiAccordionDetails-root {
      p {
        color: black;
      }
    }
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: initial;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: initial;
    }
    p {
      color: $mcf-lightblue;
      font-weight: 500;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 599px) {
    .faq {
      padding: 100px 10px;
    }
  }

  // SUBFOOTER
  .subfooter {
    background: url("../../../assets//mcf/gbreadman.jpg") no-repeat center
      center;
    background-size: cover;
    padding: 100px;
  }
  .subfooter__container {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
  }
  .subfooter__box {
    padding: 1px;
    max-width: 800px;
    border-radius: $mcf-radius;
    background-color: white;
    text-align: center;
    img {
      display: block;
      margin: 20px auto;
    }
  }
  .subfooter__title {
    color: $mcf-blue;
    font-size: 2.5rem;
    font-weight: 900;
    padding: 10px 20px;
  }
  .subfooter__text {
    color: $mcf-blue;
    font-size: 1.375rem;
    font-weight: 500;
    display: block;
    padding: 10px 20px;
  }
  .subfooter__cta {
    color: white;
    font-size: 1.9375rem;
    font-weight: 800;
    border-radius: $mcf-radius;
    border: none;
    padding: 10px 25px;
    background-color: $mcf-green;
    position: relative;
    bottom: -45px;
  }
  .subfooter__sign {
    position: relative;
    display: block;
    margin-top: 60px;
    margin: 60px auto 0px;
  }
  @media screen and (max-width: 1279px) {
    .subfooter {
      padding: 50px 0px;
    }
  }
  @media screen and (max-width: 599px) {
    .subfooter__cta {
      font-size: 1.5rem;
    }
  }
  // FOOTER
  .footer {
    background-color: $mcf-blue;
    padding: 100px;
  }
  .footer__container1 {
    display: flex;
    max-width: 80%;
    margin: auto;
    justify-content: center;
    align-items: center;
    img {
      max-width: 85%;
      margin: 0px 40px;
    }
    span {
      color: white;
      font-size: 1.25rem;
      font-weight: 500;
      margin: 50px;
    }
  }
  .footer__container {
    display: flex;
    max-width: 80%;
    margin: auto;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }
    span {
      color: white;
      font-size: 1.25rem;
      font-weight: 500;
      margin: 50px;
    }
  }
  @media screen and (max-width: 1279px) {
    .footer {
      padding: 50px 0px;
    }
    .footer__container {
      flex-direction: column;
    }
    @media screen and (max-width: 768px) {
      .footer__container1 {
        flex-direction: column;
      }
    }
  }
  // FOOTER NAV
  .footernav {
    background-color: $mcf-blue;
    color: white;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    padding: 10px;
    a {
      margin: 20px;
    }
  }
  @media screen and (max-width: 959px) {
    a {
      display: block;
    }
  }
  // LH
  .lh {
    background-color: $mcf-blue;
    text-align: center;
    color: white;
    font-weight: bold;
  }
}
