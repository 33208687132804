.products__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 20px;
    padding: 20px;
    border-radius: 15px;

    .loader {
        align-self: flex-end;
    }
}
