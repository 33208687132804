.values {
  background-color: $orange-background;
  &__container {
    padding: 75px 120px;
    margin: auto;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    @include tablet {
      padding: 50px 25px;
    }
  }
  &__title {
    font-family: $kansas;
    font-size: 38px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    @include phone {
      font-size: 26px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    @include phone {
      flex-direction: column;
      text-align: center;
    }
    &--item {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 33%;
      @include phone {
        width: 100%;
        align-items: center;
        max-width: 400px;
        // text-align: center;
        margin: auto;
      }
      .title {
        font-family: $kansas;
        font-size: 30px;
        font-weight: 600;
        margin: 0;
        @include phone {
          font-size: 22px;
        }
      }
      .text {
        font-family: $europa;
        font-size: 18px;
        line-height: 140%;
        margin: 0;
        color: $grey90;
        flex-grow: 1;
      }
      img {
        width: 100%;
      }
    }
  }
}