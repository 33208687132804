$dark-teal: #017093;
$side-margins: 0 20px 0 20px;

.searchbar {
    * {
        font-family: Montserrat;
        color: white;
    }

    background-image: linear-gradient(-15deg, $dark-teal, $dark-teal 40%, $akt-light-blue 40%, $akt-light-blue);
    color: white;
    border-radius: 0px 0px 15px 15px;
    margin-bottom: 20px;

    .MuiBreadcrumbs-li:first-child {
        font-weight: bold;
    }

    h1 {
        font-weight: bold;
        font-size: 36px;
        line-height: 43.88px;
    }

    .search {
        display: flex;
        align-items: center;
        gap: 3rem;
        margin: $side-margins;

        .search__field {
            flex-grow: 1;
        }
    }

    .breadcrumbs {
        margin: $side-margins;
        padding-top: 20px;
    }
}
