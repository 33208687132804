.howOrder {
    margin-top: 10px;
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 273px;
        margin: auto;
        .MuiAccordionSummary-content {
            align-items: center;
            justify-content: space-between;
        }
        .MuiAccordionSummary-expandIcon {
            transform: rotate(45deg);
        }
        .MuiAccordionSummary-expandIcon.Mui-expanded {
            transform: rotate(180deg);
        }
        .MuiButtonBase-root {
          width: 34px;
          height: 34px;
          // transform: rotate(45deg);
          img {
            width: 100%;
          }
        }
        &--logo {
            max-width: 20px;
            max-height: 20px;
        }
        &--text {
            font-family: $europa;
            font-size: 18px;
            margin: 0;
        }
        &--switch {
            max-width: 25px;
            transform: rotate(45deg);
            transition: transform 0.3s;
            cursor: pointer;
            &.open {
                transform: rotate(0deg);
                transition: transform 0.3s;
            }
        }
    }
    .how__steps {
        max-width: 1280px;
        margin: 50px auto;
        text-align: center;
    }
    .footer__bar {
        margin-top: 30px;
    }
}
