.customize {
  position: relative;
  background-color: $orange-background;
  &__container {
    max-width: 1280px;
    margin: auto;
    padding: 75px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    @include tablet {
      padding: 50px 25px;
    }
  }
  &__title {
    font-family: $kansas;
    font-size: 38px;
    font-weight: 600;
    margin: 0;
    text-align: center;
    @include phone {
      font-size: 26px;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    @include phone {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 75px;
    }
    &--item {
      display: flex;
      flex-direction: column;
      gap: 25px;
      max-width: 350px;
      width: 33%;
      @include phone {
        width: 100%;
      }
      .title {
        font-family: $kansas;
        font-size: 20px;
        font-weight: 600;
        flex-grow: 1;
        margin: 0;
      }
      .text {
        font-family: $europa;
        font-size: 18px;
        line-height: 140%;
        color: $grey90;
        margin: 0;
      }
      .image {
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 25px;
      }
    }
  }
	&::after {
		content: url("../../../../assets/bs/poivron.png");
		position: absolute;
		top: -100px;
		left: 0;
    transform: matrix(-1, 0 , 0, 1, 0, 0);
		@include phone {
			visibility: hidden;
		}
	}
}