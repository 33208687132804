.container {
    font-family: "Inter", sans-serif;
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin-bottom: 20px;

    .title {
        color: black;
        font-size: 1.1rem;
    }

    .label {
        font-weight: bold;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.modal {
    min-width: 500px;
    span {
        font-size: 1.2rem;
    }
    .option {
        display: flex;
        justify-content: space-between;
    }

    .with__suboptions {
        .main__option {
            cursor: pointer;
            text-transform: capitalize;
            font-weight: bold;
        }

        .suboptions {
            padding-left: 20px;

            .suboption {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
