.login__page {
    background-color: $akt-light-gray;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    &.faim {
        background-image: url(../../../assets/shared/affiliateBanner-mcf.jpg);
    }
    &.Matcha {
        background-image: url(../../../assets/shared/affiliateBanner-gm.jpg);
    }
    &.akt {
        background-image: url(../../../assets/akt/akt-login-bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        display: block;
    }
    &.Choko {
        background-image: url(../../../assets/shared/affiliateBanner-gc.jpg);
    }
    &.Krisp {
        background-image: url(../../../assets/shared/affiliateBanner-gk.jpg);
    }
    &.Gummy {
        background-image: url(../../../assets/shared/affiliateBanner-gg.jpg);
    }
}
.login__container {
    max-width: 500px;
    &.akt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px !important;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.login__logo {
    cursor: pointer;
    display: block;
    margin: auto;
    width: 300px;
    padding-bottom: 20px;
}
.login__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0.6rem 1.5rem;
}
.login__cta {
    color: white !important;
    &.faim {
        background-color: $mcf-lightblue;
        &:hover {
            background-color: $mcf-lightblue !important;
        }
    }
    &.Matcha {
        background-color: $gm-green;
        &:hover {
            background-color: $gm-green !important;
        }
    }
    &.Aktivation {
        background-color: $akt-light-blue;
        font-family: $Montserrat;
        text-transform: uppercase;
        border-radius: 8px;
        font-weight: 700;
        padding: 13px 40px;
        // font-size: 18px;
        &:hover {
            background-color: $akt-light-blue !important;
        }
    }
    &.Choko {
        background-color: $gc-brown;
        &:hover {
            background-color: $gc-brown !important;
        }
    }
    &.Krisp {
        background-color: $gk-red;
        &:hover {
            background-color: $gk-red !important;
        }
    }
}
.akt__subscribed__user {
    text-align: center;
    font-family: $Roboto;
}
