// CORPO
.corpopop {
    font-family: $Roboto;
    overflow: hidden;
    .MuiDialog-paperWidthSm {
        min-width: 50vw;
    }
    .MuiDialogContent-root {
        overflow: hidden;
    }
}
.corpopop__title 
    .MuiTypography-h6 {
    font-size: 1.5rem;
    color: $akt-light-blue;
    font-weight: 700;
}
.corpopop__text {
    font-size: 1.25rem;
}
.corpopop__point {
    font-size: 1.25rem;
    display: block;
}
.corpopop__cta {
    border-radius: $gm-radius;
    border: none;
    color: white;
    font-size: 1rem;
    padding: 16px 35px;
    font-weight: 900;
    text-transform: uppercase;
    background-color: $akt-light-blue;
    margin-top: 20px;
    margin-bottom: 20px;
}