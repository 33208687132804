.promo__modal {
  font-family: $Montserrat;
  padding: 15px;
}
.promo__title {
  margin: 0;
  color: #007faa;
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}
.promo__container {
  display: flex;
  flex-direction: row;
  @include mixin960 {
    flex-direction: column;
  }
}
.promo__item {
  margin: 0 50px;
  text-align: center;
  flex: 1 1 0;
  img {
    width: 250px;
    max-width: 100%;
  }
  p {
    color: #484848;
    line-height: 1.5;
    font-size: 1.25rem;
  }
}
.promo__cta {
    background-color: #1386ad;
    color: white;
    font-weight: 700;
    border-radius: 8px;
    padding: 13px 40px;
    transition: all 0.2s;
    border: none;
    display: block;
    margin: auto;
    margin-top: 20px;
    &:hover {
        background-color: $akt-dark-teal;
    }
}