.process {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 30px;
    padding-bottom: 40px;
    @include phone {
        flex-direction: column;
        gap: 15px;
    }
    &__item {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
            font-family: $europa;
            font-size: 17px;
            margin: 0;
        }
        &--step {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid #4a4a4a20;
            border-radius: 60px;
            opacity: 0.5;
        }
        &--text {
            opacity: 0.6;
        }
    }
    &__create {
        .process__item {
            &.selected {
                .process__item--step {
                    background-color: $orange-background;
                    color: $orange;
                    opacity: 1;
                    border: 2px solid #f6faeb;
                    font-weight: 700;
                }
                .process__item--text {
                    opacity: 1;
                    color: black;
                    font-weight: 700;
                }
            }
        }
    }
    &__try {
        .process__item {
            &.selected {
                .process__item--step {
                    background-color: $green-background;
                    color: $green;
                    opacity: 1;
                    border: 2px solid #f6faeb;
                    font-weight: 700;
                }
                .process__item--text {
                    opacity: 1;
                    color: black;
                    font-weight: 700;
                }
            }
        }
    }
}
