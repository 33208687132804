.about {
	position: relative;
	// background-color: $orange-background;
	&__container {
		max-width: 1280px;
		display: flex;
    justify-content: space-between;
		align-items: center;
		margin: auto;
    gap: 100px;
		padding: 75px 120px;
		@include tablet {
			padding: 50px 25px;
      gap: 50px;
		}
    @include phone {
      flex-direction: column;
    }
	}
  &__right {
    width: 50%;
    @include tablet {
      width: 100%;
    }
    &--section {
      font-family: $europa;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      color: $green;
      letter-spacing: 1px;
    }
    &--title {
      font-family: $kansas;
      font-size: 38px;
      font-weight: 600;
      @include phone {
        font-size: 26px;
      }
    }
    &--text {
      color: $grey90;
      font-family: $europa;
      font-size: 16px;
      margin-bottom: 30px;
      line-height: 150%;
    }
    &--buttons {
      display: flex;
      gap: 25px;
    }
  }
  &__left {
    width: 50%;
    @include tablet {
      width: 100%;
    }
    &--image {
      @include tablet {
        width: 100%;
        // min-width: 300px;
        
      }
    }
  }
}
