.container form {
    display: grid;
    grid-gap: 20px;
    grid-template-areas:
        "street-number street"
        "app-number city"
        "province country"
        "postal-code .";

    .street__number {
        grid-area: street-number;
    }
    .street {
        grid-area: street;
    }
    .app__number {
        grid-area: app-number;
    }
    .city {
        grid-area: city;
    }
    .province {
        grid-area: province;
    }
    .country {
        grid-area: country;
    }
    .postal__code {
        grid-area: postal-code;
    }
}
