.affiliate {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 57%;
        height: 100%;
        background-color: rgba(2, 123, 170, 0.05);
        z-index: -1;
        border-radius: 0 0 300px 0;
    }
    &__background {
        max-width: 100%;
    }
    &__top {
        display: flex;
        @include tablet {
            flex-direction: column;
        }
    }
    &__left {
        display: flex;
        &--title {
            padding-left: 200px;
            background-color: #027baa;
            @include tablet {
                text-align: center;
                padding: 50px;
            }
            @include phone {
                padding: 50px 25px;
            }
            h5 {
                padding-right: 50px;
                margin: 50px 0;
                font-size: 60px;
                font-weight: 900;
                line-height: 1.5;
                font-family: $Montserrat;
                color: #035164;
                text-transform: uppercase;
                @include tablet {
                    padding-right: 0;
                    font-size: 47px;
                }
                @include phone {
                    font-size: 28px;
                    margin: 0;
                }
            }
        }
    }
    &__left {
        width: 57%;
        display: flex;
        flex-direction: column;
        @include tablet {
            width: 100%;
        }
    }
    &__right {
        width: 43%;
        @include tablet {
            width: 100%;
        }
        img {
            width: 100%;
        }
    }
    &__bottom {
        padding: 50px 200px 100px;
        @include tablet {
            padding: 75px;
        }
        @include phone {
            padding: 50px 25px 100px;
        }
        &--form {
            .MuiFormControl-root {
                background-color: #e1e1e1;
                margin: 1rem 0;
                border-radius: 8px;
                .MuiInputLabel-animated {
                    margin-left: 16px;
                }
                .MuiInputBase-root {
                    margin: 16px;
                }
                .MuiInput-underline:before {
                    border-bottom: 0;
                }
                .MuiInput-underline:hover:not(.Mui-disabled):before {
                    border-bottom: 0;
                }
                .MuiInput-underline.Mui-focused:after {
                    border-bottom: 0;
                }
                .MuiInput-underline:after {
                    border-bottom: 0;
                }
            }
        }
    }
    &__textfield {
        width: 49%;
        &:nth-child(odd) {
            margin-left: 2%;
        }
    }
    &__no-bg {
        background-color: unset !important;
        font-family: $Montserrat;
        font-weight: bold;
        font-weight: 20px;
    }
    &__socials {
        display: flex;
        gap: 50px;
        .MuiFormGroup-root{
            flex-direction: row;
        }
    }
}
.affiliate__container {
    padding-left: 200px;
    .MuiFormControl-root {
        margin-top: 20px;
    }
    legend {
        font-family: $Roboto;
    }
    &.faim {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $mcf-blue;
        }
    }
    &.Matcha {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $gm-green;
        }
    }
    &.Aktivation {
        margin: 0;
        @include tablet {
            padding: 50px 100px;
        }
        @include phone {
            padding: 50px 25px;

        }
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $akt-light-blue;
        }
        
    }
    &.Choko {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $gc-gold;
        }
    }
    &.Krisp {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $gk-red;
        }
    }
}
.affiliate__title {
    line-height: 1.5;
    padding-right: 50px;
    margin: 50px 0;
    font-size: 1.5rem;
    font-family: $Roboto;
    &.faim {
        color: $mcf-blue;
    }
    &.Matcha {
        color: $gm-green;
    }
    &.Aktivation {
        color: #484848;
    }
    &.Choko {
        color: $gc-brown;
    }
    &.Krisp {
        color: $gk-dark-beige;
    }
}
.affiliate__cta {
    padding: 13px 100px;
    border-radius: 30px;
    border: none;
    font-family: $Roboto;
    font-weight: bold;
    color: white;
    float: right;
    margin-top: 16px;
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: black;
        border-radius: 8px;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
        background-color: $gk-red;
    }
}
@media screen and (max-width: 1200px) {
    .affiliate__container {
        left: inherit;
        margin: 5px;
    }
    .affiliate__top {
        justify-content: flex-end;
    }
}
