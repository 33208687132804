.partners {
  max-width: 1280px;
  margin: auto;
  padding: 75px 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 75px;
  @include tablet {
    padding: 50px 25px;
  }
  &__title {
    font-family: $kansas;
    font-size: 38px;
    font-weight: 600;
    margin: 0;
    @include phone {
      font-size: 26px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 100px;
      justify-items:stretch;
    }
    @include phone {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }
}