.making {
  max-width: 1280px;
  margin: auto;
  padding: 75px 120px;
  @include tablet {
    padding: 75px 25px;
  }
  &__title {
    font-family: $kansas;
    font-size: 38px;
    font-weight: 600;
    text-align: center;
    @include phone {
      font-size: 26px;
    }
  }
  &__content {
    display: flex;
    gap: 25px;
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 50px;
    }
    @include phone {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
    .item {
      width: 25%;
      display: flex;
      flex-direction: column;
      gap: 25px;
      @include tablet {
        width: unset;
      }
      @include phone {
        text-align: center;
        align-items: center;
        max-width: 300px;
        margin: auto;
      }
      &__step {
        font-family: $kansas;
        font-size: 32px;
        font-weight: 600;
        margin: 0;
        @include phone {
          font-size: 22px;
        }
      }
      &__text {
        font-family: $europa;
        font-size: 18px;
        color: $grey90;
        line-height: 140%;
        flex-grow: 1;
        margin: 0;
      }
      &__image {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}