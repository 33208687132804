.storelocator__title {
    text-align: center;
    font-family: $Roboto;
}
#storelocator__map {
    width: 100%;
    height: calc(100vh - 152px);
}
.storelocator__list {
    height: calc(100vh - 152px);
    overflow-y: auto;
}
.storelocator__list__item {
    font-family: $Roboto;
    padding: 10px;
    cursor: pointer;
    &.selected {
        color: white;
        .storelocator__list__city {
            color: white;
        }
    }
}
.storelocator__list__name {
    font-size: 1.25rem;
    display: block;
}
.storelocator__list__city {
    font-size: 1rem;
    color: grey;
}
.storelocator__map__box {
    padding: 20px;
    border-radius: 25px;
    color: white;
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        bottom: -20px;
        animation: slideIn 0.75s infinite ease-in-out;
    }
    @keyframes slideIn {
        0% {
            bottom: 0px;
        }
        100% {
            bottom: -20px;
        }
    }
}
.storelocator__address {
    opacity: 0.6;
}
.faim {
    .storelocator__list__item {
        &.selected {
            background-color: $mcf-blue;
        }
    }
    .storelocator__map__box {
        background-color: $mcf-blue;
        &:after {
            border-top: 20px solid $mcf-blue;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        }
    }
}
.Matcha {
    .storelocator__list__item {
        &.selected {
            background-color: $gm-green;
        }
    }
    .storelocator__map__box {
        background-color: $gm-green;
        &:after {
            border-top: 20px solid $gm-green;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        }
    }
}
.Choko {
    .storelocator__list__item {
        &.selected {
            background-color: $gc-brown;
        }
    }
    .storelocator__map__box {
        background-color: $gc-brown;
        &:after {
            border-top: 20px solid $gc-brown;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        }
    }
}

@media screen and (max-width: 1410px) {
    #storelocator__map {
        width: 100%;
        height: calc(100vh - 104px);
    }
    .storelocator__list {
        height: calc(100vh - 104px);
    }
}

@media screen and (max-width: 959px) {
    .storelocator__list {
        height: 40vh;
    }
}
