.mission {
  position: relative;
  &__container {
    max-width: 1280px;
    margin: auto;
    padding: 75px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @include tablet {
      padding: 50px 25px;
    }
  }
  &__section {
    font-family: $europa;
    font-size: 12px;
    font-weight: 700;
    color: $orange;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  &__title {
    font-family: $kansas;
    font-size: 38px;
    font-weight: 600;
    z-index: 10;
    @include phone {
      font-size: 26px;
    }
  }
  &::after {
    content: url("../../../../assets/bs/parsley.png");
    position: absolute;
    top: 50%;
    left: -100px;
    @include tablet {
      bottom: -175px;
      top: unset;
    }
    @include phone {
      bottom: -200px;
    }
  }
  &::before {
    content: url("../../../../assets/bs/poire.png");
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    @include tablet {
      visibility: hidden;
    }
  }
}