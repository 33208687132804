.discover {
  position: relative;
  background-color: $green-background;
  &__container {
    max-width: 1280px;
    margin: auto;
    padding: 75px 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 150px;
    @include tablet {
      padding: 50px 25px;
      flex-direction: column-reverse;
      gap: 50px;
      text-align: center;
    }
  }
  &__left {
    width: 50%;
    @include tablet {
      width: 100%;
    }
    &--image {
      width: 100%;
      max-width: 500px;
    }
  }
  &__right {
    width: 50%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    @include tablet {
      width: 100%;
      align-items: center;
    }
    &--title {
      font-family: $kansas;
      font-size: 38px;
      font-weight: 600;
      margin: 0;
      @include phone {
        font-size: 26px;
      }
    }
    &--text {
      font-family: $europa;
      font-size: 16px;
      color: $grey90;
      line-height: 150%;
      margin: 0;
    }
  }
  &::after {
    content: url("../../../../assets/bs/laitue.png");
    position: absolute;
    bottom: -75px;
    right: 0;
    transform: matrix(-1, 0, 0, 0.99, 0, 0);
    @include tablet {
      visibility: hidden;
    }
  }
}