// general
#slider__container {
    margin: auto;
    max-width: 800px;

    img {
        margin: auto;
        max-width: 100%;
        height: auto;
    }

    .slick-next:before {
        color: $akt-light-blue;
    }
    .slick-prev:before {
        color: $akt-light-blue;
    }
}
// mobile
@media only screen and (max-width: 599px) {
}

// desktop
@media only screen and (min-width: 600px) {
}

/* --- [Custom made image viewer] --- */
// general settings
// img {
//     cursor: pointer;
//     width: 100%;
//     height: auto;
//     object-fit: cover;
// }

// #slider {
//     display: flex;
//     img {
//         opacity: 0.5;

//         &:hover {
//             opacity: 1;
//         }
//     }
//     #selected {
//         opacity: 1;
//     }
// }

// // mobile
// @media only screen and (max-width: 599px) {
//     #selected__image {
//     }

//     #slides {
//         overflow-x: hidden;
//         img {
//             width: 150px;
//         }
//     }
// }

// // desktop
// @media only screen and (min-width: 600px) {
//     #slider__container {
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         #slides {
//             flex-direction: column;
//         }
//     }
// }
