.newsletter {
    background-image: url("../../../../assets/bs/newsletter.png");
    background-repeat: no-repeat;
    background-size: cover;
    @include phone {
        background-image: url("../../../../assets/bs/newsletter-phone.png");
    }
    &__container {
        max-width: 1280px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 120px;
        text-align: center;
        @include tablet {
            padding: 75px 25px;
        }
        .klaviyo-form {
            width: 100%;
            max-width: 650px !important;
            input {
                border: 1px solid rgba(74, 74, 74, 0.16) !important;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12) !important;
                border-radius: 39px !important;
                height: 70px !important;
                padding-left: 30px !important;
                &::placeholder {
                    font-family: $europa !important;
                    font-size: 16px !important;
                    color: $grey !important;
                    line-height: 140% !important;
                }
                &:hover {
                    border: 1px solid rgba(74, 74, 74, 0.16) !important;
                }
            }
            button {
                background: linear-gradient(90deg, #e7762d 0%, #f99f24 100%) !important;
                border-radius: 64px !important;
                color: white !important;
                font-family: $europa !important;
                font-size: 15px !important;
                font-weight: 700 !important;
                padding: 14px 32px !important;
                position: absolute;
                top: 25%;
                right: 40px;
                width: max-content;
                cursor: pointer !important;
                &:hover {
                    cursor: pointer !important;
                    background: linear-gradient(90deg, #e7762d 0%, #f99f24 164.57%);
                    &::after {
                      cursor: pointer !important;
                        position: absolute;
                        top: -4px;
                        left: -4px;
                        content: "";
                        width: 100%;
                        height: 100%;
                        padding: 4px;
                        background-color: rgba(231, 118, 45, 0.24);
                        box-shadow: 0px 4px 16px rgba(253, 127, 43, 0.32);
                        border-radius: 80px;
                    }
                }
                @include phone {
                    top: unset;
                    bottom: -50px;
                    right: 23px;
                }
            }
        }
    }
    &__section {
        font-family: $europa;
        font-size: 12px;
        font-weight: 700;
        color: $orange;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    &__title {
        font-family: $kansas;
        font-size: 30px;
        font-weight: 600;
        max-width: 650px;
        @include phone {
            font-size: 22px;
        }
    }
    &__image {
        width: 100%;
        max-width: 545px;
    }
}
