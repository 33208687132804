.questions {
    max-width: 1280px;
    margin: auto;
    padding: 0 120px 75px;
    @include tablet {
        padding: 0 25px 50px;
    }
    &__accordion {
        box-shadow: none !important;
        &--title {
            font-family: $kansas;
            font-size: 38px;
            font-weight: 600;
            margin: 0;
            @include phone {
                font-size: 26px;
            }
        }
        &--subtitle {
            font-family: $kansas;
            font-size: 24px;
            font-weight: 600;
            margin: 0;
        }
        &--text {
            font-family: $europa;
            font-size: 18px;
            color: $grey90;
            line-height: 150%;
            padding-bottom: 20px;
        }
    }
    .MuiAccordionDetails-root {
        display: block;
    }
    &__accordion.Mui-expanded {
        &::after {
            position: absolute;
            left: 0;
            bottom: -16px;
            right: 0;
            height: 1.5px;
            content: "";
            opacity: 1;
            background-color: $green;
        }
    }
    .MuiPaper-root.MuiAccordion-root.Mui-expanded:before {
        opacity: 1 !important;
    }
    .MuiAccordionSummary-expandIcon {
        transform: rotate(45deg);
    }
    .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(180deg);
    }
}

.products {
    .questions {
        margin: 0;
        padding: 0;
        max-width: unset;
    }
}

.howOrder {
    .questions__accordion.Mui-expanded {
        &::after {
            opacity: 0;
        }
    }
}
