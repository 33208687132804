.layout {
    display: flex;
    .MuiAppBar-root {
        background-color: $akt-light-blue;
    }
    .MuiPaper-elevation4 {
        box-shadow: none;
    }
    .MuiDrawer-paperAnchorDockedLeft {
        border-right: none;
    }
    .nav__container {
        .MuiPaper-root {
            background-color: white;
            color: black;
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
        }
        .MuiSvgIcon-root {
            color: black;
        }
    }
    .nav__desktop__drawer {
        display: flex;
        width: 70px;
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        flex-shrink: 0;
        white-space: nowrap;
    }
    .nav__logo__container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;
        min-height: 64px;
        position: relative;
        margin-bottom: 24px;
        margin-top: 24px;
    }
    .nav__logo {
        max-height: 70px;
        width: 70px;
    }
    .nav__toggle__container {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .nav__header__container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
    @media screen and (max-width: 960px) {
        .nav__header__container {
            justify-content: space-between;
            .MuiSvgIcon-root {
                color: white;
            }
        }
    }
    .nav__header__options {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    // Inner Nav
    .innerNav__container {
        position: relative;
        background-color: white;
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
    }

    // Main content
    .main__content {
        flex-grow: 1;
        overflow-x: hidden;
        margin: 0;
        padding: 0;
    }

    .main__toolbar {
        display: flex;
        padding: 0px 8px;
        min-height: 56px;
        align-items: center;
        justify-content: flex-end;
    }

    @media (min-width: 600px) {
        min-height: 64px;
    }

    @media (min-width: 0px) and (orientation: landscape) {
        min-height: 48px;
    }

    // Navigation
    .nav__link__container {
        text-align: center;
        .MuiListItem-button:hover {
            background-color: inherit;
        }
        .nav__listItem {
            margin-bottom: 5px;
        }
        .nav__link__item {
            padding-bottom: 20px;
        }
        a {
            font-size: 11px;
            letter-spacing: 1px;
        }
        .nav__item__text {
            margin-top: 5px;
            font-family: $Roboto;
            font-size: 0.7rem;
            text-transform: uppercase;
        }
    }

    .nav__listItem {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px 0px;
        .MuiListItemIcon-root {
            min-width: 0;
            min-width: 0;
            .MuiSvgIcon-root {
                font-size: 20px;
            }
        }
    }
    // Mobile Nav
    .nav__mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding-bottom: 13.5px;
        padding-top: 13.5px;
        background-color: $akt-light-blue;
    }

    @media screen and (max-width: 959px) {
        .nav__link__container {
            display: inline-block;
            .nav__link__item {
                display: inline-block;
                padding-right: 10px;
                padding-bottom: 0px;
            }
        }
    }

    .nav__mobile__fab {
        z-index: 1;
        position: fixed;
        bottom: 10px;
        left: 10px;
        color: white;
        background-color: $akt-light-blue;
        .MuiIconButton-edgeStart {
            margin-left: 0;
        }
    }
}
