.subscription {
	max-width: 1280px;
	margin: auto;
	padding: 75px 120px;
	display: flex;
	flex-direction: column;
	gap: 50px;
	justify-content: center;
	align-items: center;
	text-align: center;
  @include tablet {
    padding: 50px 25px;
  }
	&__title {
		font-family: $kansas;
		font-size: 38px;
		font-weight: 600;
    @include phone {
      font-size: 26px;
    }
	}
	&__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 25px;
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 75px;
    }
    @include phone {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
		.subscription__card {
			box-shadow: 0px 22px 16px rgba(0, 0, 0, 0.08);
      border-radius: 25px;
      padding: 35px 25px;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      @include tablet {
        width: unset;
      }
      &--top {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 25px;
        font-family: $europa;
        font-size: 13px;
        font-weight: 700;
        white-space: nowrap;
      }
      &--title {
        font-family: $kansas;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        padding-bottom: 5px;
        max-width: 200px;
      }
      &--text {
        font-family: $europa;
        font-size: 16px;
        color: $grey90;
        margin: 0;
        padding-bottom: 15px;
        max-width: 200px;
      }
      &--prices {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .new-price {
          font-family: $europa;
          font-size: 24px;
          font-weight: 700;
          color: $green;
          margin: 0;
        }
        .old-price {
          font-family: $europa;
          font-size: 16px;
          font-weight: 700;
          opacity: 0.4;
          text-decoration: line-through;
          margin: 0;
        }
      }
      &--per {
        font-family: $europa;
        font-size: 16px;
        color: $grey90;
      }
      &--economique {
        background-color: $yellow-background;
        color: $yellow;
      }
      &--populaire {
        background-color: $red-background;
        color: $red;
      }
      &--decouverte {
        background-color: $blue-background;
        color: $blue;
      }
		}
	}
}
