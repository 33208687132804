@import "../../../../scss/parts/shared/_variables.scss";
.container {
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;

    .content {
        padding: 10px;
        background-color: white;
        display: flex;
        column-gap: 30px;

        .images {
            display: flex;
            flex-direction: column;
            gap: 20px;

            img {
                border-radius: 5px;
            }

            img.main {
                max-width: 500px;
                height: auto;
            }

            .rest {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                img {
                    width: 100%;
                    max-width: 100px;
                    height: auto;
                }
            }
        }
        .info {
            color: $akt-dark-teal;
            .name {
                font-weight: bold;
            }

            .description {
            }

            .secondary__description {
            }

            .quantity {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .price {
                font-weight: bold;
                font-size: 3rem;
            }

            .out__of__stock {
                color: red;
                text-transform: italic;
            }

            button.add__to__cart {
                border: none;
                background-color: $akt-dark-teal;
                color: white;
                font-weight: bold;
                border-radius: 15px;
                padding: 10px 40px;
            }

            .video__preview {
                margin-top: 10px;
            }
        }
    }

    .similar__products {
        background-color: #f6f6f6;
        h1 {
            font-size: 2rem;
            color: $akt-dark-teal;
            font-weight: bold;
            text-align: center;
        }

        hr {
            width: 200px;
            border: 2px solid #324c5e;
            border-radius: 15px;
            color: transparent;
        }
        .products {
            display: flex;
            flex-wrap: wrap;
            column-gap: 15px;
        }
    }
}
