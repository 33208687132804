$side-margin: 20px;
.container {
    display: flex;

    .content {
        margin-left: $side-margin;
        margin-right: $side-margin;
        flex-grow: 1;

        .main {
            background-color: #eee;
            padding: 15px;
            border-radius: 15px;
        }
    }
    .sidebar {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
