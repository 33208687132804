// WORKOUTS
.workouts__banner {
    img {
        width: 100%;
        height: auto;
    }
}
.workouts__header {
    font-family: $Montserrat;
}
.workouts__thumbnail {
    position: relative;
    img {
        object-fit: contain;
        width: 100%;
        height: auto;
        cursor: pointer;
        display: block;

        &:hover {
            opacity: 0.5;
        }
    }

    .workouts__fav__button {
        z-index: 1000;
        position: absolute;
        min-width: 50px;
        min-height: 50px;
        right: 0;
    }
}
.workouts__title {
    margin-left: 10px;
    margin-bottom: 0px;
}
.workouts__description {
    font-family: $Montserrat;
    font-size: 1.15rem;
    margin: 0px 10px 20px 10px;
}
// WORKOUT
.workout__videoplayer__container {
    position: relative;
}
.workout__skip__button {
    position: absolute !important;
    right: 0;
    top: 45%;
    background-color: $akt-light-blue !important;
    color: white !important;
    font-weight: bold !important;
    .MuiButton-label {
        font-size: 2rem;
    }
}
.workout__margin__container {
    width: 85%;
    margin: auto;
}
.workout__banner {
    margin-top: 50px;
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.workout__main__title {
    font-size: 2.75rem;
    color: $akt-light-blue;
    font-weight: 700;
}
.workout__title {
    font-size: 2rem;
    color: $akt-light-blue;
    font-weight: 700;
}
.workout__info__container {
    display: flex;
    justify-content: space-between;
}
.workout__info__text {
    font-size: 1.175rem;
    text-transform: uppercase;
    margin-right: 20px;
}
.workout__description {
    font-size: 1.25rem;
    text-align: justify;
    margin-top: 40px;
    margin-bottom: 40px;
}
.workout__objectives__title {
    font-weight: 600;
    font-size: 1.175rem;
    text-transform: uppercase;
    margin-right: 20px;
}
.workout__objectives__text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: uppercase;
    display: block;
    &::before {
        content: url("../../../assets/akt/akt-mini.png");
        padding: 10px;
        max-width: 50%;
        position: relative;
        top: 10px;
    }
}
.workout__phase__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0px;
}
.workout__phase__description {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 30px;
    display: block;
}
.workout__item__title {
    display: block;
    font-weight: 600;
    font-size: 1.25rem;
    font-family: $Roboto;
    text-align: center;
}

.workout__thumbnail__container {
    position: relative;
    img {
        object-fit: contain;
        width: 100%;
        height: auto;
        cursor: pointer;
        display: block;

        &:hover {
            opacity: 0.5;
        }
    }

    .workouts__fav__button {
        z-index: 1000;
        position: absolute;
        min-width: 50px;
        min-height: 50px;
        right: 0;
    }
}
.workout__thumbnail {
    max-width: 100%;
    display: block;
    cursor: pointer;
}

.workout__unavailable {
    color: red;
}
.workout__divider {
    border-bottom: 2px solid #ccc;
    background-color: #dadada;
    height: 2px;
    margin: 40px 0;
    span {
        display: block;
        width: 50px;
        height: 2px;
        background-color: $akt-light-blue;
    }
}

.workout__partners__logo {
    text-align: center;
    img {
        max-width: 100%;
        height: 200px;
        object-fit: contain;
    }
}

.workout__trainers__container {
    .slick-prev:before,
    .slick-next:before {
        color: $akt-light-blue;
    }
    img {
        max-width: 300px;
        height: auto;
        display: inline;
    }

    .workout__trainer {
        width: 100%;
        display: grid !important;
        grid-template-columns: 1fr 3fr;
        grid-gap: 20px;
        margin: auto;
        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        .workout__trainer__details {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .workout__trainer__description {
            font-size: 1.1rem;
            max-width: 80%;
        }
    }
    @media only screen and (max-width: 768px) {
        .slick-list {
            height: unset !important;
        }
    }
}

.workout__individual__files__container {
    margin-top: 40px;
    margin-bottom: 40px;
    img {
        max-width: 300px;
        height: auto;

        &:hover {
            cursor: pointer;
        }
    }
    .MuiGrid-container {
        margin-bottom: 20px;
    }
}

.workout__related__products {
    margin: auto;
    img {
        max-width: 100%;
        height: auto;
    }
}

.workout__current__video {
    position: relative;
    & * {
        margin-top: 0;
        margin-bottom: 0;
    }
    .workout__current__video__info {
        padding: 1rem;
        color: white;
        background: $akt-light-blue;
        align-items: center;
    }
    .workout__current__video__title {
        font-weight: bold;
        font-size: 1.75rem;
        display: inline-block;
    }
    .workout__current__video__difficulty {
        margin-left: 10px;
        background-color: white;
        text-transform: uppercase;
        color: $akt-light-blue;
        font-weight: bold;
        padding: 8px 16px;
        float: right;
    }
    .workout__current__video__type {
        display: block;
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .workout__current__video__description {
        font-size: 1.25rem;
        width: 80%;
    }
    .workout__current__video__equipment__container {
        margin-top: 20px;
        span {
            font-size: 1.25rem;
            font-weight: bold;
            text-decoration: underline;
        }
        li {
            font-size: 1.25rem;
        }
    }
}

.workout__doc__thumbnail {
    cursor: pointer;
    max-width: 100%;
    height: auto;
    display: block;
}
// FAVORITES
.favorites__videos__container {
    margin-top: 60px;
}

@media screen and (max-width: 599px) {
    .workout__info__container {
        flex-direction: column;
    }
}
