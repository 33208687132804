.footer {
  position: relative;
  &__container {
    max-width: 1280px;
    margin: auto;
    padding: 50px 120px 7px;
    @include tablet{
      padding: 50px 25px 7px;
    }
  }
  &__linksContainer {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      margin-bottom: 50px;
    }
  }
  &__links {
    margin-right: 10%;
    @include tablet {
      grid-column-start: 1;
      grid-column-end: -1;

    }
    &--socials {
      display: flex;
      gap: 14px;
      margin-bottom: 25px;
      @include phone {
        justify-content: center;
      }
    }
  }
  &__nav {
    display: flex;
    flex-direction: column;
    gap: 14px;
    &--menus {
      grid-column-start: 2;
    }
    &--concept {
      grid-row-start: 2;
    }
    &--title {
      font-family: $kansas;
      font-size: 16px;
      font-weight: 600;
      max-width: 150px;
    }
    a {
      .link {
        font-family: $europa;
        font-family: 16px;
        line-height: 140%;
        color: $grey;
      }
    }
  }
  &__bar {
    position: relative;
    width: 100%;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-top: 1px solid #4A4A4A16;
      width: 100%;
      height: 1px;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    &--politics {
      font-family: $europa;
      font-size: 14px;
      color: $grey90;
    }
    &--copyright {
      font-family: $europa;
      font-size: 14px;
      @include phone {
        visibility: hidden;
      }
    }
    &--leadhouse {
      font-family: $europa;
      font-size: 14px;
      color: $grey90;
      text-align: end;
      a {
        color: #AF67C0;
        font-weight: 700;
      }
    }
  }
  &::after {
    content: url("../../../../assets/bs/feuilles.png");
    position: absolute;
    bottom: 75px;
    right: 0;
    @include tablet {
      bottom: unset;
      top: 100px;
    }
    @include phone {
      top: 50px;
    }
  }
}
.copyright {
  visibility: hidden;
  @media screen and (max-width: 768px) {
    visibility: initial;
    text-align: center;
    margin-bottom: 25px;;
  }
}