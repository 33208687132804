.button {
    border-radius: 80px;
    padding: 1.25rem 2.25rem;
    font-family: $europa;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    &__cta {
        border: unset;
        color: white;
        &--orange {
            background: $orange-linear;
            &:hover {
                position: relative;
                background: linear-gradient(90deg, #e7762d 0%, #f99f24 164.57%);
                &::after {
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    content: "";
                    width: 100%;
                    height: 100%;
                    padding: 4px;
                    background-color: rgba(231, 118, 45, 0.24);
                    box-shadow: 0px 4px 16px rgba(253, 127, 43, 0.32);
                    border-radius: 80px;
                }
            }
        }
        &--green {
            background: $green-linear;
            &:hover {
                position: relative;
                background: linear-gradient(90deg, #52923c 0%, #9fcb26 152.14%);
                &::after {
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    content: "";
                    width: 100%;
                    height: 100%;
                    padding: 4px;
                    background-color: rgba(130, 163, 51, 0.24);
                    box-shadow: 0px 4px 16px rgba(82, 146, 60, 0.32);
                    border-radius: 80px;
                }
            }
        }
        &--darkGreen {
            background: $green;
            &:hover {
                position: relative;
                background: linear-gradient(90deg, #52923c 0%, #9fcb26 152.14%);
                &::after {
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    content: "";
                    width: 100%;
                    height: 100%;
                    padding: 4px;
                    background-color: rgba(130, 163, 51, 0.24);
                    box-shadow: 0px 4px 16px rgba(82, 146, 60, 0.32);
                    border-radius: 80px;
                }
            }
        }
    }
    &__regular {
        border: 2px solid rgba(74, 74, 74, 0.08);
        background-color: transparent;
        align-items: center;
        color: black;
        &--socials {
            position: relative;
            height: 50px;
            width: 50px;
            padding: 0;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &__play {
        background-color: white;
        border: 0;
        box-shadow: 0px 4px 16px rgba(35, 35, 35, 0.16);
        width: 56px;
        height: 56px;
        position: relative;
        color: black;
        svg {
            width: 25px;
            height: 25px;
            position: absolute;
            top: 50%;
            left: 52%;
            transform: translate(-50%, -50%);
        }
    }
}
