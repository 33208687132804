.lh-splash-screen {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $akt-light-blue;
    z-index: 99999;
    pointer-events: none;
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
      background-color: $gk-dark-beige;
  }
}

.lh-splash-screen .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.lh-splash-screen .logo {
    width: 256px;
    margin: 0 auto;
}

.lh-splash-screen .logo img {
    filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2));
}

.lh-splash-screen .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px;
}

.lh-splash-screen .spinner-wrapper .spinner {
    position: absolute;
    overflow: hidden;
    left: 50%;
    margin-left: -50px;
    animation: outer-rotate 2.91667s linear infinite;
}

.lh-splash-screen .spinner-wrapper .spinner .inner {
    width: 100px;
    height: 100px;
    position: relative;
    animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

.lh-splash-screen .spinner-wrapper .spinner .inner .gap {
    position: absolute;
    left: 49px;
    right: 49px;
    top: 0;
    bottom: 0;
    border-top: 10px solid;
    box-sizing: border-box;
}

.lh-splash-screen .spinner-wrapper .spinner .inner .left,
.lh-splash-screen .spinner-wrapper .spinner .inner .right {
    position: absolute;
    top: 0;
    height: 100px;
    width: 50px;
    overflow: hidden;
}

.lh-splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
.lh-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 10px solid white;
    border-bottom-color: transparent;
    border-radius: 50%;
}

.lh-splash-screen .spinner-wrapper .spinner .inner .left {
    left: 0;
}

.lh-splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
    left: 0;
    border-right-color: transparent;
    animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

.lh-splash-screen .spinner-wrapper .spinner .inner .right {
    right: 0;
}

.lh-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
    right: 0;
    border-left-color: transparent;
    animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}
