.team {
  max-width: 1280px;
  padding: 75px 120px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include tablet {
    padding: 50px 25px 0;
  }
  &__title {
    font-family: $kansas;
    font-size: 54px;
    font-weight: 600;
    @include phone {
      font-size: 32px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-around;
    gap: 50px;
    @include phone {
      flex-direction: column;
    }
    &--item {
      display: flex;
      flex-direction: column;
      max-width: 500px;
      width: 50%;
      @include phone {
        width: 100%;
      }
      img {
        width: 100%;
      }
      .name {
        font-family: $kansas;
        font-size: 38px;
        font-weight: 600;
        margin: 15px 0 0 0;
        @include phone {
          font-size: 26px;
        }
      }
      .position {
        font-family: $europa;
        font-size: 18px;
        font-weight: 700;
        color: #4A4A4A;
        text-align: start;
      }
      .text {
        font-family: $europa;
        font-size: 16px;
        line-height: 140%;
        color: $grey90;
      }
    }
  }
}