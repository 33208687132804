@mixin tablet {
  @media only screen and (max-width: 1280px) {
      @content
  }
}

@mixin phone {
  @media only screen and (max-width: 768px) {
      @content
  }
}