@mixin tablet {
    @media only screen and (max-width: 1280px) {
        @content;
    }
}

@mixin mixin960 {
    @media only screen and (max-width: 960px) {
        @content;
    }
}

@mixin phone {
    @media only screen and (max-width: 768px) {
        @content;
    }
}
