.summer {
    min-height: 60vh;
    background-color: $gc-brown;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
    font-size: 1.25rem;
    div {
        max-width: 70%;
    }
    img {
        max-width: 100px;
    }
}