.gm {
    // // HERO
    .hero {
        height: calc(100vh - 152px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero__background {
        position: absolute;
        left: 0;
        z-index: -1;
        height: fit-content;
        object-fit: cover;
        max-width: 100%;
    }
    .hero__gluten {
        position: absolute;
        bottom: 10px;
        right: 10px;
        max-width: 30%;
    }
    .hero__background__mobile {
        display: none;
        position: absolute;
        height: fit-content;
        object-fit: cover;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        max-width: 100%;
    }
    .hero__container {
        position: relative;
        right: -350px;
        .hero__1 {
            color: black;
            font-size: 5rem;
            font-weight: 600;
        }
        .hero__cta {
            margin-top: 25px;
        }
        .hero__cta1 {
            border-radius: $gm-radius;
            border: none;
            color: white;
            font-size: 1.25rem;
            font-weight: 400;
            padding: 16px 35px;
        }
        .hero__cta1 {
            background-color: $gm-green;
        }
        img {
            max-width: 30%;
            margin-top: 25px;
        }
    }
    @media screen and (max-width: 1109px) {
        .hero__gluten {
            width: 40%;
        }
        // .hero__background {
        //     display: none;
        // }
        // .hero__background__mobile {
        //     display: block;
        // }
        .hero {
            justify-content: flex-end;
        }
        .hero__container {
            right: unset;
            padding: 10px;
        }
        // .hero {
        //     align-items: flex-end;
        // }
    }
    @media screen and (max-width: 959px) {
        .hero__gluten {
            display: none;
        }
        .hero__background {
            display: none;
        }
        .hero__background__mobile {
            display: block;
        }
        .hero__container {
            right: unset;
            padding: 10px;
        }
        .hero {
            align-items: flex-end;
            justify-content: center;
        }
    }
    @media screen and (max-width: 1410px) {
        .hero {
            height: calc(100vh - 104px);
        }
    }
    @media screen and (max-width: 599px) {
        .hero__container {
            .hero__1 {
                font-size: 4rem;
            }
        }
    }
    // INTRO
    .intro {
        margin: 40px 0;
        position: relative;
        .slick-slide {
            text-align: center;
        }
    }
    .intro__item {
        position: relative;
        z-index: 4;
        width: 80% !important;
        .intro__top {
            text-align: center;
            background-color: #F7F9F9;
            border-top-left-radius: $gm-radius;
            border-top-right-radius: $gm-radius;
            img {
                max-width: 75%;
                margin: auto;
            }
        }
        .intro__bottom {
            background-color: $gm-green;
            text-align: center;
            border-bottom-left-radius: $gm-radius;
            border-bottom-right-radius: $gm-radius;
            span {
                color: white;
                font-weight: 400;
                font-size: 1.25rem;
                padding: 15px;
                display: block;
                max-width: 80%;
                margin: auto;
            }
        }
    }
    // CADEAU
    .cadeau {
        max-width: 100%;
        overflow: hidden;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .cadeau__container {
        background-color: black;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 75px;
    }
    .cadeau__1 {
        font-size: 3.5rem;
        font-weight: 900;
        color: white;
        display: block;
    }
    .cadeau__2 {
        font-size: 3.5rem;
        font-weight: 900;
        color: white;
        display: block;
    }
    .cadeau__3 {
        font-size: 1.5rem;
        font-weight: 300;
        color: white;
        display: block;
        margin-top: 40px;
    }
    .cadeau__4 {
        font-size: 1.5rem;
        font-weight: 800;
        color: white;
        display: block;
    }
    .cadeau__price__container, .cadeau__price__cents, .cadeau__price__pack {
        color: white;
    }
    .cadeau__price__container, .cadeau__price {
        font-size: 6.5625rem;
        font-weight: 900;
    }
    .cadeau__price__container {
        position: relative;
        display: block;
        margin-bottom: 40px;
    }
    .cadeau__price__cents {
        font-size: 2.5rem;
        font-weight: 900;
    }
    .cadeau__price__pack {
        font-weight: 900;
        font-size: 0.875rem;
        position: relative;
        left: -60px;
        top: -20px;
    }
    .cadeau__price__pack2 {
        font-weight: 900;
        font-size: 0.875rem;
        position: relative;
        left: -60px;
        top: -20px;
    }
    .cadeau__cta {
        margin-top: 40px;
        button {
            border-radius: $gm-radius;
            border: none;
            color: $gm-green;
            font-size: 1.5rem;
            padding: 16px 35px;
            font-weight: 900;
            background-color: white;
        }
    }
    .cadeau__includes {
        color: $gm-green;
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: 5px;
        display: block;
    }
    @media screen and (max-width: 1279px) { 
        .cadeau__container {
            padding: 40px;
            height: inherit;
        }
    }
    @media screen and (max-width: 599px) { 
        .cadeau__price__pack2 {
            display: block;
            left: 0;
            top: 0;
        }
    }
    // PRODUCTS
    .products {
        max-width: 70%;
        margin: 0 auto 80px auto;
    }
    .promo__desktop {
        img {
            max-width: 100%;
        }
    }
    .promo__mobile {
        display: none;
    }
    .products__title {
        text-align: center;
        font-size: 4.875rem;
        font-weight: 600;
        color: $gm-green;
    }
    .products__item {
        max-width: 375px;
        margin: 40px auto;
    }
    .products__item__info {
        background-color: #eee;
        border-top-left-radius: $gm-radius;
        border-top-right-radius: $gm-radius;
        padding: 25px 20px;
        position: relative;
    }
    .products__item__disclaimer {
        font-size: 0.9rem;
        position: absolute;
        top: 10px;
        right: 20px;
    }
    .products__item__name {
        color: black;
        font-size: 1.4rem;
        font-weight: 500;
    }
    .products__item__price {
        color: $gm-green;
        font-weight: 500;
        font-size: 1.4rem;
        float: right;
    }
    .products__item__image {
        background-color: #FBFBFB;
        text-align: center;
        img {
            max-width: 90%;
            padding: 40px 0;
        }
    }
    .products__item__cta {
        background-color: black;
        border-bottom-left-radius: $gm-radius;
        border-bottom-right-radius: $gm-radius;
        padding: 25px 20px;
        text-align: center;
        button {
            color: white;
            font-size: 1.75rem;
            font-weight: 500;
            background: none;
            border: none;
        }
    }
    @media screen and (max-width: 1650px) { 
        .products {
            max-width: 80%;
        }
    }
    @media screen and (max-width: 1450px) { 
        .products {
            max-width: 90%;
        }
    }
    @media screen and (max-width: 1279px) { 
        .products {
            max-width: 70%;
        }
    }
    @media screen and (max-width: 599px) { 
        .products__item__name, .products__item__price {
            font-size: 1.25rem;
        } 
    }
    // SAMPLE
    .sample {
        max-width: 100%;
        overflow: hidden;
        img {
            width: 100%;
            display: block;
        }
    }
    .sample__container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }
    .sample__title {
        font-size: 3rem;
        font-weight: 400;
        color: $gm-green;
        display: block;
        padding: 15px;
    }
    .sample__subtitle {
        font-size: 1.25rem;
        font-weight: 700;
        color: black;
        display: block;
        padding: 15px;
    }
    .sample__text {
        font-size: 1.25rem;
        font-weight: 500;
        color:black;
        display: block;
        padding: 15px;
    }
    .sample__cta {
        margin-top: 15px;
        border-radius: $gm-radius;
        border: none;
        color: white;
        font-size: 1.5rem;
        padding: 16px 35px;
        font-weight: 500;
        background-color: $gm-green;
        display: block;
    }
    @media screen and (max-width: 959px) {
        .sample__container {
            height: inherit;
        }
    }
    // VALEURS
    .valeurs {
        background-color: #f1f1f1;
        padding: 75px 10px;
        .MuiTabs-root {
            background-color: $gm-green;
        }
        .MuiTab-textColorInherit {
            color: white;
            font-weight: bold;
        }
        .Mui-selected {
            color: white;
        }
        .MuiTabs-indicator {
            background-color: black;
            height: 4px;
        }
        .PrivateTabIndicator-colorSecondary-3 {
            background-color: black;
        }
        .PrivateTabIndicator-root-1 {
            height: 4px;
        }
    }
    .valeurs__title {
        padding-bottom: 50px;
        text-align: center;
        font-size: 5rem;
        font-weight: 700;
        color: $gm-green;
        margin-block-end: 0;
        margin-block-start: 0;
    }
    .valeurs__tabs {
        max-width: 70%;
        margin: auto;
    }
    .valeurs__left, .valeurs__right {
        padding: 30px;
    }
    .valeurs__left {
        background-color: white;
        text-align: center;
        width: 100%;
    }
    .valeurs__right {
        background-color: #F8F8F8;
        width: 100%;
        text-align: center;
    }
    .valeurs__gm {
        max-height: 40vh;
        max-width: 100%;
    }
    .valeurs__nv {
        max-height: 50vh;
        max-width: 100%;
    }
    .valeurs__gluten {
        text-align: center;
        margin-top: 50px;
        img {
            max-width: 100%;
        }
    }
    // SOCIALS
    .socials {
        background: url('../../../assets/shared/socials.png') no-repeat center left;
        background-color: #333;
        background-size: contain;
    }
    .socials__container {
        width: 80%;
        margin: auto;
    }
    .socials__title {
        color: white;
        font-size: 2.625rem;
        font-weight: 900;
        padding: 50px 0;
        margin: 0;
    }
    .socials__icons {
        float: right;
        img {
            margin: 0px 10px;
        }
        a {
            display: inline;
        }
    }
    @media screen and (max-width: 1279px) { 
        .socials {
            background-size: cover;
        }
        .socials__icons {
            display: block;
            float: unset;
        }
    }
    // USE
    .use {
        max-width: 100%;
        overflow: hidden;
        img {
            height: 100%;
        }
    }
    .use__container {
        background-color: #f1f1f1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }
    .use__1 {
        font-size: 5rem;
        font-weight: 900;
        color: black;
        display: block;
    }
    .use__2 {
        font-size: 5rem;
        font-weight: 900;
        color: $gm-green;
        display: block;
    }
    .use__3 {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-size: 3.125rem;
            font-weight: 500;
            color: black;
        }
        img {
            max-width: 100%;
        }
    }
    .use__cta {
        margin-top: 40px;
        button {
            border-radius: $gm-radius;
            border: none;
            color: black;
            font-size: 1.5rem;
            padding: 16px 35px;
            font-weight: 900;
            background-color: white;
        }
    }
    @media screen and (max-width: 599px) { 
        .use {
            img {
                right: 150px;
                position: relative;
            }
        }
        .use__3 {
            flex-direction: column;
            img {
                margin-top: 25px;
            }
        }
        .use__container {
            display: block;
        }
    }
    // ANNICK
    .annick {
        max-width: 100%;
        overflow: hidden;
        .annick__bg {
            height: 100%;
        }
    }
    .annick__container {
        background-color: $gm-green;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }
    .annick__quote1 {
        margin-bottom: 25px;
    }
    .annick__quote2 {
        display: inline;
        float: right;
        transform: rotate(180deg);
    }
    .annick__1 {
        font-size: 3rem;
        font-weight: 700;
        color: white;
        display: block;
    }
    .annick__2 {
        font-size: 2.5rem;
        font-weight: 500;
        color: white;
        margin-top: 60px;
        display: block;
    }
    .annick__cta {
        margin-top: 40px;
        button {
            border-radius: $gm-radius;
            border: none;
            color: black;
            font-size: 1.5rem;
            padding: 16px 35px;
            font-weight: 900;
            background-color: white;
        }
    }
    @media screen and (max-width: 599px) { 
        .annick {
            .annick__bg {
                right: 320px;
                position: relative;
            }
        }
        .annick__container {
            display: block;
            max-width: 100%;
        }
        .annick__quote1, .annick__quote2 {
            max-width: 25px;
            display: block;
            float: inherit;
        }
    }
    // VERSUS
    .vs {
        background-color: white;
        margin-bottom: 80px;
    }
    .vs__title {
        text-align: center;
        color: $gm-green;
        font-size: 6.25rem;
        font-weight: 500;
        b {
            color: black;
        }
    }
    .vs__container {
        text-align: center;
        img {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 599px) { 
        .vs__title {
            font-size: 4rem;
        }
    }
    // FACT
    .fact {
        background: url('../../../assets//gm/fact-bg.png') no-repeat center center;
        background-size: cover;
        padding: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    h3 {
        font-size: 3.5rem;
        font-weight: 900;
        color: white;
        display: block;
    }
    .fact__1 {
        font-size: 2.5rem;
        font-weight: 400;
        color: white;
        display: block;
    }
    .fact__2 {
        font-size: 2.5rem;
        font-weight: 700;
        color: white;
        display: block;
        margin-top: 20px;
    }
    // SHIPPING
    .shipping {
        background: url('../../../assets/gm/shipping-bg.jpg') no-repeat center center;
        background-size: cover;
        padding: 40px;
        h3 {
            text-align: right;
            color: white;
            font-weight: 500;
            font-size: 2.75rem;
        }
    }
    @media screen and (max-width: 1279px) { 
        .shipping {
            background: none;
            background-color: #0f0f0f;
            h3 {
                text-align: center;
            }
        }
    }
    // TESTIMONIALS 
    .testimonials {
        background-color: #F7F7F7;
        color: white;
        padding: 60px;
    }
    .testimonials__item {
        width: 80%;
        background-color: white;
        padding: 30px 20px;
        border-radius: $gm-radius;
        margin: 20px;
        position: relative;
        p {
            color: $gc-brown;
            opacity: 0.8;
        }
        span {
            color: $gc-brown;
            font-weight: bold;
        }
        &.offset {
            margin-left: 40px;
        }
        img {
            max-width: 90%;
        }
        .testimonials__content {
            img {
                position: absolute;
                right: 10px;
                bottom: 10px;
            }
        }
    }
    .testimonials__right {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .testimonials__right__2 {
        display: block;
        font-size: 3.5rem;
        color: $gm-green;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .testimonials__cta {
        border-radius: $gm-radius;
        border: none;
        color: white;
        font-size: 1.5rem;
        padding: 16px 35px;
        font-weight: 900;
        background-color: $gm-green;
        display: block;
        transition: 0.5s;
        &:hover {
            color: $gm-green;
            background-color: white;
            transition: 0.5s;
        }
    }
    // FAQ 
    .faq {
        padding: 100px;
    }
    .faq__title {
        font-size: 3.75rem;
        font-weight: 500;
        color: black;
        text-align: center;
    }
    .faq__container {
        width: 80%;
        margin: auto;
        display: flex;
        .MuiPaper-elevation1 {
            box-shadow: none;
        }
        .MuiIconButton-label {
            background-color: $gm-green;
        }
        .MuiAccordionDetails-root {
            p {
                color: black;
            }
        }
        .MuiSvgIcon-root {
            color: white;
        }
        .MuiAccordionSummary-root.Mui-expanded {
            min-height: initial;
        }
        .MuiAccordionSummary-content.Mui-expanded {
            margin: initial;
        }
        p {
            color: $gm-green;
            font-weight: 500;
            font-size: 16px;
        }
    }
    @media screen and (max-width: 599px) {
        .faq {
            padding: 100px 10px;
        }
    }
    // NEWSLETTER
    .newsletter {
        background: url('../../../assets/gm/newsletter.jpg');
        background-position: center;
        background-size: cover;
        background-position: center;
        padding: 150px 20px;
    }
    .newsletter__title, .newsletter__text {
        color: white;
        display: block;
        text-align: center;
    }
    .newsletter__title {
        font-size: 3.75rem;
        font-weight: 700;
    }
    .newsletter__text {
        font-size: 1.5rem;
        font-weight: 600;
    }
    @media screen and (max-width: 599px) {
        .newsletter {
            padding: 100px 10px;
        }
    }
    // FOOTER
    .footer {
        background-color: #222;
        padding: 100px;
    }
    .footer__container {
        display: flex;
        max-width: 80%;
        margin: auto;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
            margin: 0px 40px;
        }
        span {
            color: white;
            font-size: 1.25rem;
            font-weight: 500;
            margin: 50px;
        }
    }
    .footer__disclaimer {
        color: white;
        opacity: 0.5;
        display: block;
        text-align: right;
        margin-top: 30px;
    }
    @media screen and (max-width: 1279px) {
        .footer {
            padding: 50px 0px;
        }
        .footer__container {
            flex-direction: column;
        }
    }
    @media screen and (max-width: 599px) {
        .footer__container {
            img {
                margin: 10px 0px;
            }
        }
    }
    // FOOTER NAV
    .footernav {
        background-color: #222;
        color: white;
        font-size: 1.25rem;
        font-weight: 500;
        text-align: center;
        padding: 10px;
        a {
            margin: 20px;
        }
    }
    @media screen and (max-width: 959px) {
        a {
            display: block;
        }
    }
    // LH
    .lh {
        background-color: $gm-green;
        text-align: center;
        color: white;
        font-weight: bold;
    }
}