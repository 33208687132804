.checkout__form {
    height: 100%;

    &__error {
        color: red;
    }

    &__error::before {
        display: inline;
        content: "⚠ ";
    }
    & > div {
        width: 100%;
    }
    &__card__title {
        color: #1373b8;
    }
}

.checkout {
    background-color: #e3e3e3;
    min-height: calc(100vh - 152px);
}
.checkout__guest__option {
    span {
        font-size: 1.25rem;
        font-weight: bold;
    }
}
.checkout__promocode {
    .MuiInputLabel-root {
        font-weight: bold;
    }
}
.checkout__next {
    &.faim {
        background-color: $mcf-blue;
        color: white;
    }
    &.Matcha {
        background-color: $gm-green;
        color: white;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
        color: white;
    }
    &.Choko {
        background-color: $gc-brown;
        color: white;
    }
    &.Krisp {
      background-color: $gk-red;
      color: white;
  }
}
.continue__shop {
    position: absolute;
    left: -184px;
    top: 152px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    animation: continueshopslide 1s ease-in-out forwards;
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
      background-color: $gk-red;
  }
}
.continue__shop__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: white;
    padding: 10px 15px;
    width: auto;
    font-family: $Roboto;
    font-size: 1.25rem;
    .MuiSvgIcon-root {
        margin-right: 1rem;
    }
}
.continue__shop__text {
    animation: continueshophidetext 6s ease-in-out forwards;
}
@keyframes continueshophidetext {
    0% {
        display: block;
    }
    100% {
        display: none;
        position: absolute;
        z-index: -1;
    }
}
@keyframes continueshopslide {
    0% {
        left: -184px;
    }
    100% {
        left: 0px;
    }
}
@media screen and (max-width: 599px) {
    .MuiStepLabel-label {
        display: none !important;
    }
    .MuiTableCell-root {
        padding: 8px !important;
    }
    .continue__shop {
        top: 106px;
    }
}

.duo__container {
    .MuiTextField-root {
        padding-bottom: 10px;
    }
    .MuiFormControl-root {
        padding: 10px;
    }
}
