.engagementBanner {
	position: relative;
	background-color: $orange-background;
	&__container {
		max-width: 1280px;
		display: flex;
    justify-content: space-between;
		align-items: center;
		margin: auto;
    gap: 100px;
		padding: 75px 120px;
		@include tablet {
			padding: 50px 25px 75px;
      gap: 50px;
		}
    @include phone {
      flex-direction: column;
    }
	}
  &__left {
    &--section {
      font-family: $europa;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      color: $orange;
      letter-spacing: 1px;
    }
    &--title {
      font-family: $kansas;
      font-size: 38px;
      font-weight: 600;
      @include phone {
        font-size: 26px;
      }
    }
    &--buttons {
      display: flex;
      gap: 25px;
    }
  }
  &__right {
    width: 100%;
    &--image {
      @include tablet {
        width: 100%;
        // min-width: 300px;
        
      }
    }
  }
  &::after {
    content: url("../../../../assets/bs/oranges.png");
    position: absolute;
    bottom: -75px;
    left: 0;
    @include tablet {
      visibility: hidden;
    }
    @include phone {
      visibility: initial;
      content: url('../../../../assets/bs/oranges-phone.png');
      top: -35px;
      bottom: unset;
      left: unset;
      right: 0;
    }
  }
}
