// variables for boutique only
$selected: rgba(159, 159, 159, 1);

.main__content {
    margin: 30px;
    .boutique__product {
        .slick-dots {
            bottom: -125px;
            @media only screen and (max-width: 1315px) {
                bottom: -200px;
            }
            @media only screen and (max-width: 959px) {
                bottom: -85px;
            }
            @media only screen and (max-width: 387px) {
                bottom: -165px;
            }
            li {
                width: 75px;
                @media only screen and (max-width: 959px) {
                    width: 60px;
                }
            }
        }
        .container {
            // margin: 20px;
            background-color: #f0f0f0;
            @media only screen and (max-width: 400px) {
                background-color: rgba(0, 0, 0, 0);
            }
            #slider__container {
                margin-top: 50px;
                @media only screen and (max-width: 959px) {
                    margin-top: 0px;
                }
            }
            .title {
                font-size: 26px;
                color: $mcf-blue;
                @media only screen and (max-width: 959px) {
                    margin-top: 75px;
                }
                @media only screen and (max-width: 387px) {
                    margin-top: 150px;
                }
            }
            .price {
                font-weight: 600;
                margin-right: 20px;
                font-family: $Montserrat;
                color: $akt-light-blue;

                &__original {
                    text-decoration: line-through;
                    font-style: italic;
                    font-size: 2rem;
                }

                &__discounted {
                    color: red;
                    font-size: 2.5rem;
                }
            }
            .product-description {
                position: relative;
                padding: 15px 0px;
                margin: 30px 0px;
                h1,
                p {
                    color: $mcf-darkblue;
                }
            }
            .product-description::before {
                content: "";
                position: absolute;
                width: 300px;
                border-top: solid 2px $mcf-darkblue;
            }
            .product-description::after {
                content: "";
                position: absolute;
                width: 300px;
                border-bottom: solid 2px $mcf-darkblue;
            }
            .sku {
                img {
                    cursor: pointer;
                    max-width: 50px;

                    &:hover {
                        border: 1px solid black;
                    }
                }
            }
            .add__to__cart__btn {
                width: 100%;
                margin: 50px 0px 25px 0px;
            }

            .sku {
                display: flex;
                min-height: 50px;
                min-width: 100px;
                border: 1px solid black;
                cursor: pointer;
                justify-content: center;
                align-items: center;

                &__selected,
                &:hover {
                    background: black;
                    color: white;
                }
            }

            .unavailable {
                color: $shared-light-gray;
                &:hover {
                    background: $shared-light-gray;
                }
            }

            .out__of__stock {
                margin-top: 10px;
                margin-bottom: 10px;
                padding: 10px;
                height: 30px;
                text-align: center;
                background: $shared-light-gray;
            }
        }
    }

    .sku__prop {
        outline: 1px solid #c4c4c4;
        border-radius: 15px;
        background-color: #eee;
        cursor: pointer;
        width: 95px;
        height: 42px;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            outline: solid 2px $selected;
        }
    }

    img.sku__img {
        width: 100%;
        height: auto;
    }

    .sku__img {
        outline: none;
    }

    .selected {
        color: white;
        background-color: $akt-dark-teal;
        outline: 1px solid $selected;
    }

    .disabled {
        cursor: not-allowed;
    }

    .disabled.sku__img {
        filter: grayscale(100%);
        opacity: 0.3;
    }

    .disabled.crossed {
        color: $selected;
        background: linear-gradient(
            to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            $selected 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%
        );
    }
}

.boutique__fab {
    right: 16px !important;
    bottom: 16px !important;
    position: fixed !important;
}

.boutique__boutique {
    display: flex;

    .boutique__toolbar {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
    }
    .MuiGrid-container {
        @media only screen and (max-width: 768px) {
            justify-content: center;
        }
        .clickable__tag {
            width: 400px;
            // margin: 5px;
            .card {
                position: relative;
                min-width: 300px;
                background-color: $prtl-product-grey;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

                .product-price {
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 16px;
                    color: white;
                    padding: 0px 10px;
                    width: fit-content;
                    background-color: $akt-dark-teal;
                }

                .product-image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        object-fit: contain;
                        width: 392px;
                        max-width: 100%;
                        height: 400px;
                    }
                }

                .product-name {
                    background-color: $akt-light-blue;
                    padding: 2px 10px;
                    width: 100%;
                    h1 {
                        font-size: 22px;
                        text-align: center;
                        color: white;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
