// Rich text field (draft-js)
.draftjs__container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 6px 0 7px;

    &:hover {
        border-color: black;
    }

    &.draftjs__focus {
        border-color: $akt-dark-teal;

        .draftjs__label {
            color: $akt-dark-teal;
        }
    }

    .DraftEditor-root,
    .public-DraftEditor-content {
        max-height: calc((1.5em + 0.75rem + 2px) * 3);
        min-height: calc((1.5em + 0.75rem + 2px) * 3);
        overflow: auto;
    }

    &.draftjs__readOnly {
        border-color: transparent;

        .DraftEditor-root {
            max-height: unset;
            overflow: unset;
        }
    }

    .draftjs__label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 0.75rem;
        font-family: $Roboto;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
    }
}

.new__note__container {
    // Font
    color: black;
    padding: 0;
    font-size: 0.875rem;
    font-family: $Roboto !important;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    // Other stuff
    min-height: 40px;
    border-radius: 3px;
    padding: 0.5rem;
    box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 0 1px rgba(9, 30, 66, 0.08);
    .new__note__options {
        padding-top: 0.5rem;
        text-align: right;
    }
    .DraftEditor-root {
        position: relative;
        .public-DraftEditorPlaceholder-root {
            position: absolute;
            top: 0;
            pointer-events: none;
        }
    }
}

.note__container {
    display: inline-flex;
    padding: 0.25rem;
    border-radius: 3px;
    margin-left: 2.5rem;
}
.note__header {
    display: flex;
    position: relative;
    .note__avatar {
        position: absolute;
    }
    .note__author {
        margin-left: 2.5rem;
        font-weight: bold;
    }
}
.note__footer {
    display: flex;
    margin-left: 2.5rem;
}
.note__timestamp {
    margin-left: 5px;
}
.note__update__editor {
    margin-left: 2.5rem;
}
.note__footer__update {
    text-align: right;
    opacity: 0.8;
}
