.gg {
    .promo {
        background: $gg-red;
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        &__text {
            margin: 0 auto;
            font-size: 18px;
            font-weight: 400;
            color: white;
            padding: 0 1.5rem;
            text-align: center;
            line-height: 1.75rem;
            text-transform: uppercase;
        }
    }
    .nav {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 50;
        &__container {
            max-width: 1720px;
            margin: auto;
            &--content {
                display: flex;
                justify-content: space-between;
                padding: 2rem 100px;
                @include tablet {
                    padding: 1rem 50px;
                    position: relative;
                }
                @include phone {
                    padding: 1rem 25px;
                }
            }
        }
        &__left {
            display: flex;
            align-items: center;
            &--logo {
                margin-right: 3.5rem;
            }
            &--links {
                display: flex;
                gap: 2rem;
                .link {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 36px;
                    text-transform: uppercase;
                    transition: font-size 0.2s;
                    background: linear-gradient(
                        90deg,
                        rgba(255, 79, 64, 1) 0%,
                        rgba(61, 92, 172, 1) 20%,
                        rgba(2, 202, 236, 1) 40%,
                        rgba(198, 221, 78, 1) 60%,
                        rgba(255, 201, 66, 1) 80%,
                        rgba(235, 84, 101, 1) 100%
                    );
                    background-clip: text;
                    &:hover {
                        font-size: 25px;
                        font-weight: 700;
                        color: transparent;
                    }
                    @include tablet {
                        font-size: 22px;
                    }
                }
                @include tablet {
                    width: 100%;
                    height: 100vh;
                    position: absolute;
                    z-index: 50;
                    right: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
            &--links.open {
                background-color: $gg-bluesky;
                left: 0;
                padding-top: 3.5rem;
            }
            &--links .active {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    background-color: $gg-red;
                    left: -2px;
                    bottom: -49px;
                    border: 2px solid $gg-red;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 1px;
                    width: 100%;
                }
            }
        }
        &__right {
            display: flex;
            align-items: center;
            gap: 2.25rem;
            &--buttons {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin-top: 1rem;
            }
            @include tablet {
                gap: 1.5rem;
            }
            svg {
                width: 20px;
                height: 20px;
            }
        }
        &__buttons {
            &--list {
                display: flex;
                gap: 1rem;
            }
        }
        &__bar {
            position: relative;
            width: 60%;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                border-top: 1px solid #4a4a4a16;
                width: 100%;
                height: 1px;
            }
        }
    }
    .hero {
        background-image: url("../../../assets/gg/hero.png");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // padding: 300px 100px 100px;
        // min-height: 500px;
        height: 60vh;
        @include phone {
            padding: 0 25px;
        }
        &__title {
            color: white;
            font-size: 60px;
            -webkit-text-stroke: 3px black;
            text-shadow: 1px 1px 48px rgba(249, 77, 61, 0.3);
            text-align: center;
            background-color: rgba(0, 0, 0, 0.3);
            padding: 10px;
            @include phone {
                font-size: 45px;
            }
        }
    }
    .sugar {
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__text {
            font-size: 90px;
            text-align: center;
            margin: 0 25px;
            @include tablet {
                font-size: 62px;
            }
            @include phone {
                font-size: 42px;
            }
        }
    }
    .ratio {
        background-image: url("../../../assets/gg/ratio.png");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 100px 25px;
        &__text {
            color: white;
            font-size: 50px;
            text-align: center;
            -webkit-text-stroke: 3px black;
            @include tablet {
                font-size: 40px;
            }
            @include phone {
                font-size: 30px;
                max-width: 310px;
            }
        }
    }
    .opening {
        background-color: $gg-blue;
        position: relative;
        overflow: hidden;
        &::after {
            content: "";
            width: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 50%;
            right: -3%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            padding-top: 100%;
            min-width: 800px;
            min-height: 800px;
            @include phone {
                content: unset;
            }
        }
        &__container {
            display: flex;
            justify-content: space-around;
            align-items: center;
            max-width: 1720px;
            margin: auto;
            @include phone {
                flex-direction: column;
                text-align: center;
            }
        }
        &__left {
            max-width: 40%;
            padding: 50px 100px;
            z-index: 5;
            @include tablet {
                padding: 50px;
            }
            @include phone {
                padding: 50px 25px 25px;
                max-width: none;
            }
            img {
                width: 100%;
                transition: all 0.4s;
                &:hover {
                    transform: scale(1.2);
                    @include tablet {
                        transform: unset;
                    }
                }
            }
        }
        &__right {
            padding: 50px 100px;
            @include tablet {
                padding: 50px;
            }
            @include phone {
                padding: 25px 25px 50px;
            }
            &--promo {
                color: $gg-yellow;
                font-size: 45px;
                font-weight: 700;
                margin: 0;
                @include tablet {
                    font-size: 35px;
                }
                @include phone {
                    font-size: 30px;
                }
            }
            &--boite {
                color: white;
                font-size: 71px;
                margin: 0;
                @include tablet {
                    font-size: 60px;
                }
                @include phone {
                    font-size: 50px;
                }
            }
            &--save {
                color: $gg-green;
                font-size: 30px;
                font-weight: 700;
                margin: 0;
                @include tablet {
                    font-size: 25px;
                }
                @include phone {
                    font-size: 20px;
                }
            }
            &--price {
                color: $gg-green;
                font-size: 85px;
                margin: 0;
                @include tablet {
                    font-size: 65px;
                }
                @include phone {
                    font-size: 45px;
                }
            }
            &--livraison {
                color: white;
                font-size: 34px;
                margin: 0;
                @include tablet {
                    font-size: 30px;
                }
                @include phone {
                    font-size: 24px;
                }
            }
            button {
                margin-top: 10px;
            }
        }
    }
    .products {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        text-align: center;
        @include phone {
            display: unset;
        }
        .product {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 100px;
            @include tablet {
                padding: 50px;
            }
            .name {
                font-size: 71px;
                color: white;
                margin: 0;
                z-index: 5;
                @include tablet {
                    font-size: 50px;
                }
            }
            img {
                max-width: 500px;
                width: 100%;
                transition: all 0.5s;
                &:hover {
                    transform: rotate(-420deg);
                    @include phone {
                        transform: unset;
                    }
                }
                @include phone {
                    max-width: 350px;
                }
            }
            button {
                z-index: 5;
            }
            .price {
                font-size: 52px;
                color: white;
                margin: 0;
                z-index: 5;
                @include tablet {
                    font-size: 33px;
                }
            }
            &.raspberry {
                background-color: $gg-red;
                button {
                    background-color: $gg-green;
                    color: white;
                }
            }
            &.watermelon {
                background-color: $gg-green;
                button {
                    background-color: $gg-red;
                    color: white;
                }
            }
            &.pineapple {
                background-color: $gg-yellow;
                button {
                    background-color: $gg-pink;
                    color: white;
                }
            }
            &.peach {
                background-color: $gg-pink;
                button {
                    background-color: $gg-yellow;
                    color: white;
                }
            }
            button {
                margin-top: 25px;
                &:hover {
                    background-color: $gg-bluesky;
                }
            }
        }
        .tvn {
            &__left {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 50px;
                @include tablet {
                    grid-column: 1 / 3;
                }
                @include phone {
                    padding: 50px 25px;
                }
                .title {
                    font-size: 70px;
                    color: $gg-bluesky;
                    margin: 0 0 20px 0;
                }
                .MuiBox-root {
                    .MuiTabs-flexContainer {
                        justify-content: center;
                        gap: 5px;
                        @include phone {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                        }
                        button {
                            border-radius: unset;
                            color: white;
                            font-weight: 300;
                            text-transform: capitalize;
                            opacity: 1;
                            min-width: 150px;
                            padding: 8px;
                            @include phone {
                                min-width: 100px;
                                font-size: 16px;
                                width: 100%;
                                max-width: unset;
                            }
                            &[aria-selected="true"] {
                                font-weight: 700;
                            }
                            &#simple-tab-0 {
                                background-color: $gg-red;
                            }
                            &#simple-tab-1 {
                                background-color: $gg-green;
                            }
                            &#simple-tab-2 {
                                background-color: $gg-yellow;
                            }
                            &#simple-tab-3 {
                                background-color: $gg-pink;
                            }
                        }
                    }
                    .MuiTabs-indicator {
                        height: 0px;
                    }
                    .tabpanel {
                        .MuiBox-root {
                            display: flex;
                            justify-content: center;
                            padding: 24px;
                            .MuiTypography-root {
                                text-align: center;
                                display: flex;
                                @include phone {
                                    flex-direction: column;
                                }
                            }
                        }
                        .ingredients {
                            max-width: 260px;
                            background-color: #f9f9f9;
                            padding: 10px;
                        }
                    }
                }
            }
            &__right {
                width: 100%;
                @include tablet {
                    grid-column: 1 / 3;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .sample {
        position: relative;
        padding: 100px;
        @include tablet {
            padding: 50px;
        }
        @include phone {
            padding: 50px 25px;
        }
        &::after {
            content: "";
            background-color: $gg-yellow;
            width: 30%;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            @include phone {
                width: 100%;
                height: 20%;
            }
        }
        &__container {
            display: flex;
            align-items: center;
            @include phone {
                flex-direction: column;
            }
        }
        &__left {
            width: 50%;
            @include phone {
                width: unset;
            }
            img {
                float: right;
                width: 100%;
                transition: all 0.4s;
                &:hover {
                    transform: scale(1.2);
                    @include tablet {
                        transform: unset;
                    }
                }
            }
        }
        &__right {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            @include phone {
                width: unset;
                gap: 10px;
            }
            &--try {
                color: $gg-pink;
                font-size: 45px;
                font-weight: 700;
                margin-bottom: 10px;
                @include tablet {
                    font-size: 35px;
                }
            }
            &--price {
                color: black;
                font-size: 70px;
                @include tablet {
                    font-size: 55px;
                }
            }
            &--shipping {
                color: $gg-pink;
                font-size: 32px;
                font-weight: 700;
                @include tablet {
                    font-size: 26px;
                }
            }
        }
    }
    .recommandation {
        background-color: $gg-blue;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        padding: 100px 100px 0;
        @include phone {
            padding: 50px 25px 0;
        }
        &__title {
            font-size: 75px;
            color: white;
            margin: 0;
            @include phone {
                font-size: 50px;
            }
        }
        &__text {
            font-size: 32px;
            color: white;
            text-align: center;
            max-width: 1250px;
            margin: 0;
            @include phone {
                font-size: 26px;
            }
        }
        img {
            width: 100%;
            max-width: 700px;
        }
    }
    .info {
        background-color: $gg-bluesky;
        &__container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            justify-items: center;
            gap: 150px;
            padding: 100px;
            max-width: 1500px;
            margin: auto;
            @include tablet {
                grid-template-columns: 1fr 1fr;
                gap: 100px;
            }
            @include phone {
                grid-template-columns: 1fr;
                padding: 50px 25px;
                gap: 25px;
            }
        }
        &__item {
            width: 100%;
            text-align: center;
            &.left {
                img {
                    transition: all 0.2s;
                    &:hover {
                        transform: rotate(45deg);
                    }
                }
            }
            &.right {
                img {
                    transition: all 0.2s;
                    &:hover {
                        transform: rotate(-45deg);
                    }
                }
            }
            img {
                width: 100%;
                max-width: 140px;
                @include tablet {
                    max-width: 200px;
                }
                @include phone {
                    max-width: 100px;
                }
            }
            .text {
                color: white;
                font-size: 38px;
            }
        }
    }
    .faq {
        background-color: $gg-yellow;
        display: flex;
        &__left {
            display: flex;
            width: 50%;
            @include tablet {
                display: none;
            }
            img {
                object-fit: contain;
                object-position: right bottom;
                width: 100%;
            }
        }
        &__right {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 100px 100px 100px 0;
            gap: 10px;
            @include tablet {
                width: 100%;
                background-image: url("../../../assets/gg/faq.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: bottom;
                padding: 50px;
            }
            @include phone {
                padding: 50px 25px;
            }
            &--title {
                color: white;
                font-size: 75px;
                @include phone {
                    margin: 0 0 50px 0;
                }
            }
        }
        &__accordion {
            width: 100%;
            border-radius: 0 !important;
            box-shadow: unset;
            &::before {
                content: unset;
            }
            &--title {
                font-family: $Kanit;
                font-size: 20px;
            }
            &--subtitle {
                font-family: $Kanit;
                font-size: 24px;
                font-weight: 600;
                margin: 0;
            }
            &--text {
                font-family: $Kanit;
                font-size: 18px;
                color: $grey90;
                line-height: 150%;
                padding-bottom: 20px;
            }
        }
        .MuiAccordionSummary-expandIcon {
            // transform: rotate(45deg);
            filter: invert(77%) sepia(25%) saturate(6928%) hue-rotate(141deg) brightness(95%) contrast(99%);
        }
        .MuiAccordionSummary-expandIcon.Mui-expanded {
            transform: rotate(720deg);
            transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            filter: invert(42%) sepia(13%) saturate(6021%) hue-rotate(331deg) brightness(102%) contrast(104%);
        }
        .MuiAccordionDetails-root {
            display: block;
        }
    }
    .try {
        padding: 60px;
        background-color: $gg-red;
        overflow: hidden;
        @include phone {
            padding: 50px 25px;
        }
        &__container {
            display: flex;
            gap: 50px;
            @include phone {
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }
        }
        &__left {
            width: 50%;
            position: relative;
            display: flex;
            align-items: center;
            @include phone {
                position: unset;
                width: unset;
            }
            img {
                float: right;
                width: 100%;
                position: absolute;
                transition: all 0.4s;
                &:hover {
                    transform: scale(1.2);
                    @include tablet {
                        transform: unset;
                    }
                }
                @include phone {
                    position: unset;
                }
            }
        }
        &__right {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include phone {
                width: unset;
                text-align: center;
            }
            &--try {
                color: white;
                font-size: 45px;
                font-weight: 700;
                margin-bottom: 10px;
                @include tablet {
                    font-size: 35px;
                }
            }
            &--price {
                color: black;
                font-size: 70px;
                @include tablet {
                    font-size: 50px;
                }
            }
            &--shipping {
                color: white;
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 20px;
                @include tablet {
                    font-size: 26px;
                }
            }
        }
    }
      // FOOTER
  .gos {
    background-color: $gg-bluesky;
    padding: 100px;
  }
  .gos__title {
      font-size: 1.5rem;
      color: black;
      font-weight: 600;
      text-align: center;
      margin-block-end: 2em;
      margin-block-start: 0;
  }
  .gos__container {
      display: flex;
      max-width: 80%;
      margin: auto;
      justify-content: center;
      align-items: center;
      img {
          max-width: 125px;
          margin: 0px 40px;
      }
      a {
        text-align: center;
      }
  }
  @media screen and (max-width: 1279px) {
      .gos {
          padding: 50px 5px;
      }
      .gos__container {
          flex-direction: column;
          text-align: center;
          img {
            margin: 20px;
          }
      }
  }
  @media screen and (max-width: 599px) {
      .gos__container {
          img {
              margin: 10px 0px;
          }
      }
  }
    .footer {
        max-width: 1720px;
        margin: auto;
        padding: 100px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        justify-content: center;
        gap: 10px;
        @include tablet {
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
        @include phone {
            grid-template-columns: 1fr;
            justify-items: center;
        }
        img {
            width: 100%;
            max-width: 200px;
            object-fit: contain;
        }
        &__col {
            @include phone {
                width: 200px;
            }
            h6 {
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;
                margin: 20px 0;
            }
            ul {
                list-style-type: none;
                padding: 0;
                li {
                    p {
                        font-size: 20px;
                        font-weight: 400;
                        text-transform: uppercase;
                        display: inline-block;
                        margin: 5px 0;
                        &:hover {
                            font-weight: 700;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .bottom {
        background-color: #e3e3e3;
        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1720px;
            margin: auto;
            padding: 0 100px;
            gap: 10px;
            @include tablet {
                padding: 0 50px;
                flex-direction: column;
            }
            @include phone {
                padding: 0 15px;
                font-size: 10px;
            }
        }
        &__left {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            text-transform: uppercase;
            @include phone {
                gap: 10px;
            }
        }
        &__right {
            span {
                &:hover {
                    color: #7776f6;
                }
            }
            @include phone {
                text-align: end;
            }
            span {
                font-weight: 700;
            }
        }
    }
}
