.orderType {
    max-width: 1280px;
    margin: auto;
    padding: 25px 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    @include tablet {
        padding: 25px 50px;
        flex-direction: column;
    }
    &__item {
        border-radius: 25px;
        border: double 1px transparent;
        background-image: linear-gradient(#f8f8f8, #f8f8f8), radial-gradient(ellipse at 50% 5%, #23232300, #23232320);
        background-origin: border-box;
        background-clip: padding-box, border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 60px;
        gap: 16px;
        @include tablet {
            padding: 25px 60px;
        }
        @include tablet {
            padding: 25px;
        }
        &--section {
            font-family: $europa;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            z-index: 5;
            margin: 0;
            letter-spacing: 1px;
        }
        &--title {
            font-family: $kansas;
            font-size: 30px;
            font-weight: 600;
            margin: 0;
            z-index: 5;
            max-width: 80%;
            @include phone {
                font-size: 22px;
                max-width: none;
            }
        }
        &--text {
            font-family: $europa;
            font-size: 15px;
            font-weight: 300;
            line-height: 140%;
            color: $grey;
            margin: 0;
            z-index: 5;
            max-width: 75%;
            @include phone {
                max-width: none;
            }
        }
        &--try {
            .orderType__item--section {
                color: $green;
                letter-spacing: 1px;
            }
        }
        &--create {
            .orderType__item--section {
                color: $orange;
                letter-spacing: 1px;
            }
        }
    }
    &__try {
        position: relative;
        overflow: hidden;
        width: 100%;
        cursor: pointer;
        &::after {
            content: url("./../../../../assets/bs/order-box.png");
            position: absolute;
            top: -40px;
            left: -250px;
            z-index: 40;
            transform: scale(0.5);
            z-index: 2;
            @include tablet {
                top: -75px;
            }
            @include phone {
                display: none;
            }
        }
        &::before {
            content: url("./../../../../assets/bs/order-box.png");
            position: absolute;
            top: -40px;
            right: -250px;
            transform: scale(0.5);
            z-index: 2;
            @include tablet {
                top: -75px;
            }
            @include phone {
                display: none;
            }
        }
        &.selected {
          pointer-events: none;
            .orderType__item--try {
                background-image: unset;
                background-color: #9fcb2620;
                &::after {
                    content: "";
                    position: absolute;
                    background-color: $green;
                    left: 10%;
                    bottom: 1px;
                    border: 2px solid $green;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 1px;
                    width: 80%;
                    @include tablet {
                        width: 50%;
                        left: 25%;
                    }
                }
            }
        }
    }
    &__create {
        position: relative;
        overflow: hidden;
        width: 100%;
        cursor: pointer;
        &::after {
            content: url("./../../../../assets/bs/order-plates.png");
            position: absolute;
            top: -60px;
            left: -280px;
            z-index: 40;
            transform: scale(0.5);
            z-index: 2;
            @include tablet {
                top: -85px;
            }
            @include phone {
                display: none;
            }
        }
        &::before {
            content: url("./../../../../assets/bs/order-plates.png");
            position: absolute;
            top: -45px;
            right: -280px;
            transform: scale(0.5);
            z-index: 2;
            @include tablet {
                top: -75px;
            }
            @include phone {
                display: none;
            }
        }
        &.selected {
          pointer-events: none;
            .orderType__item--create {
                background-image: unset;
                background-color: #f99f2420;
                &::after {
                    content: "";
                    position: absolute;
                    background-color: $orange;
                    left: 10%;
                    bottom: 1px;
                    border: 2px solid $orange;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 1px;
                    width: 80%;
                    @include tablet {
                        width: 50%;
                        left: 25%;
                    }
                }
            }
        }
    }
}
