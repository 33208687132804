.portal__container {
    flex-grow: 1;
    background-color: #fff;
    border-radius: 30px;
}

.tab__background__color {
    background-color: white;
    color: black;
}

.profile__info__container {
    .MuiGrid-grid-xs-6 {
        padding: 12px;
    }
    .MuiButton-containedPrimary {
        margin: 10px;
        float: right;
    }
    .profile__address {
        padding-top: 20px;
    }
}

.profile__info__code {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    .profile__info__code--image {
        max-width: 100%;
        object-fit: contain;
    }
    .profile__info__code--content {
        background: url("../../../assets/portal/background-code.png");
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        background-color: #012b3c;
        display: flex;
        align-items: center;
        padding: 22px;
        gap: 125px;
        padding: 30px 100px;
        padding-left: 500px;
        @media screen and (max-width: 1919px) {
            padding-left: 350px;
        }
        @media screen and (max-width: 1550px) {
            padding-left: 200px;
            gap: 50px;
        }
        @media screen and (max-width: 1200px) {
            padding-left: 100px;
        }
        @media screen and (max-width: 1024px) {
            padding: 30px;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 25px;
        }

        .text__container {
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 26px;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            .title {
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                margin: 0;
            }
        }
        .code {
            cursor: pointer;
            background-color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 40%;
            padding: 9px;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            span {
                color: #606060;
                font-size: 16px;
            }
            img {
                padding: 6px;
                &:hover {
                    background: #ececec;
                    border-radius: 4px;
                }
            }
        }
    }
}
